import React, { useState } from "react";
import Header from "../../Parts/headerComponents/header";
import Footer from "../../Parts/footerComponents/footer";
import TabNav from "../../Parts/tabNav/tabNav";
import Ethirty from "./Ethirty";
import E87 from "../e87/E87";
import { useLocation } from "react-router-dom";
import E94 from "../e94/E94";

// const e30_url = process.env.REACT_APP_VIA_E30;
// const e30_url = "http://localhost:4202/eThirty";
export default function E30(props) {
  const location = useLocation();  
  const [selectedRadioTabValue, setSelectedRadioTabValue] = useState("tab-1");
  const handleRadioTabChange = (e) => {
    setSelectedRadioTabValue(e.target.value);
  };
  return (
    <div>
       {location.pathname!="/" &&<Header layoutdesign={props.layoutdesign}/>}
      <div className="px-20 bg-blue-50 flex gap-4">
        <div className="flex-1">
        {location.pathname!="/" &&<TabNav tabs={props.layoutdesign.tabs} type='home'></TabNav>}
          <div className="secondary_tabs border-b border-b-red-500">
              <div className="tab_button-radio">
                <input
                  type="radio"
                  className="left-skew"
                  name="check-substitution-2"
                  checked={selectedRadioTabValue === "tab-1"}
                  value="tab-1"
                  onChange={handleRadioTabChange}
                  id="tab-1"
                />
                <label className="btn btn-default font-bold" htmlFor="tab-1">
                  E30
                </label>
              </div>
              <div className="tab_button-radio">
                <input
                  type="radio"
                  name="check-substitution-2"
                  value="tab-2"
                  checked={selectedRadioTabValue === "tab-2"}
                  onChange={handleRadioTabChange}
                  id="tab-2"
                />
                <label className="btn btn-default font-bold" htmlFor="tab-2">
                  E87
                </label>
              </div>
              <div className="tab_button-radio">
                <input
                  type="radio"
                  name="check-substitution-2"
                  value="tab-3"
                  checked={selectedRadioTabValue === "tab-3"}
                  onChange={handleRadioTabChange}
                  id="tab-3"
                />
                <label className="btn btn-default font-bold" htmlFor="tab-3">
                E94
                </label>
              </div>
            </div>
          <div className="pt-0 overflow-hidden bg-blue-100  w-full secondary-frame">
            
            {selectedRadioTabValue === "tab-1" &&  <Ethirty /> }

            {selectedRadioTabValue === "tab-2" && <E87 /> }

            {selectedRadioTabValue === "tab-3" && <E94 /> }
            {/* <Ethirty /> */}
            {/* <iframe
              title="E30"
              src={e30_url}
              className="w-full h-screen bg-white"
            ></iframe> */}
          </div>
        </div>
      </div>
      {location.pathname!="/" && <Footer layoutdesign={props.layoutdesign}/>}
    </div>
  );
}
