


export default function ScreensIcon(props) {
    return (
        
        <svg width="26" height="27" viewBox="0 0 26 27" fill="none" className={props.className}>
            <path d="M21.5714 3H4.42857C2.53809 3 1 4.61499 1 6.60001V19.4C1 21.385 2.53809 23 4.42857 23H12.1429H10.8571H7.85714C7.38379 23 7.72321 23 8.28571 23C9.35714 23 8.24093 23 8.71429 23H12.7321C13.2059 23 12.7321 23 13.4286 23C14.0446 23 14.9738 23 14.5 23H15.1429H13.8571H21.5714C23.4623 23 25 21.385 25 19.4V6.60001C25 4.61499 23.4623 3 21.5714 3ZM23.2857 19.4C23.2857 20.3927 22.5165 21.2 21.5714 21.2H4.42857C3.48312 21.2 2.71429 20.3927 2.71429 19.4V6.60001C2.71429 5.60728 3.48312 4.8 4.42857 4.8H21.5714C22.5165 4.8 23.2857 5.60728 23.2857 6.60001V19.4Z" 
              stroke-width="0.5"></path>
            <path d="M19 17.0806H7C6.44775 17.0806 6 17.5283 6 18.0806C6 18.6328 6.44775 19.0806 7 19.0806H19C19.5527 19.0806 20 18.6328 20 18.0806C20 17.5283 19.5527 17.0806 19 17.0806ZM9.91602 15.2578C10.106 15.4888 10.3892 15.6226 10.688 15.6226C10.9868 15.6226 11.27 15.4888 11.46 15.2578L13.231 13.1065L13.8066 13.8067C13.9932 14.0332 14.2695 14.1665 14.5625 14.1714C14.8437 14.1524 15.1367 14.0518 15.3301 13.8316L19.751 8.80178C20.1152 8.38674 20.0752 7.75491 19.6602 7.39065C19.2461 7.0259 18.6133 7.06545 18.249 7.48147L14.6045 11.628L14.0029 10.8975C13.8135 10.6665 13.5298 10.5327 13.231 10.5327C12.9321 10.5327 12.6489 10.6665 12.459 10.8975L10.688 13.0489L9.47314 11.5733C9.12256 11.1465 8.49219 11.085 8.06592 11.4365C7.63916 11.7871 7.57813 12.4175 7.9292 12.8438L9.91602 15.2578Z" 
            ></path>
            <path d="M19 17.0806H7C6.44775 17.0806 6 17.5283 6 18.0806C6 18.6328 6.44775 19.0806 7 19.0806H19C19.5527 19.0806 20 18.6328 20 18.0806C20 17.5283 19.5527 17.0806 19 17.0806Z" 
            ></path>
        </svg>

        
    )
}