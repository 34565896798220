// import { AdvButton } from '@adv/ui'
// import { RefreshIcon } from '@heroicons/react/solid'
import { useEffect, useRef, useState } from "react";
// import {
//   UpdateIoData,
//   ReadAllChannels,
//   ReadAllChannelStates,
//   UpdateCommunicationState,
// } from '@adv/sdk/ioData'
// import { useNotifications } from '@mantine/notifications'
import "./E30.css";

export default function Ethirty() {
  const [channeldata, setChanneldata] = useState([]);
  const [clampstate, setClampstate] = useState("");
  const [dockstate, setDockstate] = useState("");
  const [gatestate, setGatestate] = useState("");
  const [commStat, seCommStat] = useState("");
  const [controlState, setControlState] = useState("");
  const [remoteControlState, setRemoteControlState] = useState("");
  const [eventStat, setEventStat] = useState("");
  const [alarmStat, setAlarmStat] = useState("");
  const [terminalMessageReceived, setTerminalMessageReceived] =
    useState("Test");
  const [terminalMessageSent, setTerminalMessageSent] = useState("");
  const [terminalMessageToSend, setTerminalMessageToSend] = useState("");
  // const notifications = useNotifications()
  const sendMessageFieldRef = useRef(null);

  const [allStats, setAllStats] = useState([
    {
      ioObject: "GEM",
      channelName: "CommunicationStateCmd",
      value: "",
    },
    {
      ioObject: "GEM",
      channelName: "ControlStateCmd",
      value: "",
    },
    {
      ioObject: "GEM",
      channelName: "RemoteControlCmd",
      value: "",
    },
    {
      ioObject: "GEM",
      channelName: "TerminalMessageAckCmd",
      value: "",
    },
    {
      ioObject: "GEM",
      channelName: "TerminalMessageSendCmd",
      value: "",
    },
    {
      ioObject: "GEM",
      channelName: "TerminalMessageAckCmd",
      value: "",
    },
    {
      ioObject: "GEM",
      channelName: "TerminalMessageSendCmd",
      value: "",
    },
    {
      ioObject: "LP1",
      channelName: "Slot1",
      value: "",
    },
    {
      ioObject: "GEM",
      channelName: "Alarm.DeviceDisconnected",
      value: "",
    },
  ]);

  const ioChannels = useRef([]);

  const refreshChannels = async () => {
    // const channelStates = await ReadAllChannelStates()
    // channelStates.data.data.forEach((channel) => {
    //   const index = ioChannels.current.findIndex((c) => c.id === channel.id)
    //   if (index >= 0) {
    //     ioChannels.current[index] = channel
    //   }
    // })
    // const res = ioChannels.current
    // const stat = false
    // const sortedSlotval = res.sort((a, b) => {
    //   const channelNameA = a.channelName
    //   const channelNameB = b.channelName
    //   // Extract numeric parts from channelName
    //   const numA = parseInt(channelNameA.match(/\d+/)?.[0]) || 0
    //   const numB = parseInt(channelNameB.match(/\d+/)?.[0]) || 0
    //   // Compare numeric parts
    //   if (numA < numB) {
    //     return -1
    //   }
    //   if (numA > numB) {
    //     return 1
    //   }
    //   // If numeric parts are equal, compare entire channelName strings
    //   if (channelNameA < channelNameB) {
    //     return -1
    //   }
    //   if (channelNameA > channelNameB) {
    //     return 1
    //   }
    //   return 0
    // })
    // setChanneldata(sortedSlotval)
    // res?.map((i, j) =>
    //   i?.channelName == 'CommunicationStateStsTxt'
    //     ? seCommStat(i.data?.value)
    //     : i?.channelName == 'ControlStateStsTxt'
    //     ? setControlState(i.data?.value)
    //     : i?.channelName == 'RemoteControlSts'
    //     ? i.data?.value == '1'
    //       ? setRemoteControlState('Remote')
    //       : i.data?.value == '0'
    //       ? setRemoteControlState('Local')
    //       : setRemoteControlState(i.data?.value)
    //     : i?.channelName == 'TerminalReceiveMessage1Sts'
    //     ? setTerminalMessageReceived(i.data?.value.toString())
    //     : i?.channelName == 'MaterialReceived'
    //     ? setEventStat(i.data?.value.toString())
    //     : i?.channelName == 'Alarm.DeviceDisconnected'
    //     ? setAlarmStat(i.data?.value.toString())
    //     : ''
    // )
  };

  const fetchIOChannels = async () => {
    // const result = await ReadAllChannels()
    // const channels = result.data.data
    // channels.sort((a, b) => (a.channelName > b.channelName ? 1 : -1))
    // ioChannels.current = channels
    // // createFilterOptions(channels)
    // await refreshChannels()
  };

  const callNotification = (texts, color) => {
    // notifications.showNotification({
    //   title: texts,
    //   message: '',
    //   autoClose: 2000,
    //   color: color,
    //   loading: false,
    //   disallowClose: false,
    // })
  };

  const handleTerminalMessage = (e) => {
    const value = e.target.value;
    setTerminalMessageSent(value);
    setTerminalMessageToSend(value);
  };

  const handleCommand = async (data) => {
    // console.log('data', data)
    // const { error } = await UpdateIoData(data)
    // if (!error) {
    //   callNotification('Write Successfully!', 'lime')
    // } else {
    //   callNotification('Please check the entered value!', 'red')
    // }
  };

  const setChannel = (data) => {
    console.log("setChannel datadatadata", data);
    console.log("setChannel channeldata", channeldata);
    const channel = channeldata.find((obj) => {
      return (
        obj.ioObject === data.ioObject && obj.channelName === data.channelName
      );
    });
    if (channel) {
      channel.data.value = data.value;
    }
    console.log("channel 1", channel);

    return channel;
  };

  const handleButtonClick = (e) => {
    const elm = e.target;

    const name = elm.getAttribute("data-channel");
    const value = elm.getAttribute("data-value");

    const obj = allStats.filter((itm) => itm.channelName === name);
    console.log("allObjects Click", obj);

    if (obj) {
      obj[0].value = value;
      const channel = setChannel(obj[0]);
      handleCommand(channel);
      if (name == "TerminalMessageAckCmd") {
        setTerminalMessageReceived("");
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchIOChannels();
    };
    fetchData();
    const intervalId = setInterval(() => {
      refreshChannels();
    }, 2000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div
      className="gem-control-wrapper e-30-wrapper justify-items-stretch h-full"
      style={{ maxWidth: "1520px" }}
    >
      <div className="">
        <div className="flex justify-between gap-3 p-5 mt-3">
          <div
            className="flex-1 border-2 border-gray-400  rounded-xl p-4  relative"
            style={{ maxWidth: "250px" }}
          >
            <div
              className="text-md bg-blue-100 -mt-9 font-bold text-center"
              style={{ width: "170px" }}
            >
              Communication State
            </div>
            <div className="mb-2 mt-4 w-full">
              <input
                type="text"
                disabled
                value={commStat}
                className="display-input w-full bg-gray-200 border border-gray-300 capitalize h-10 px-1"
              />
            </div>
            <div className="grid grid-cols-2 gap-4 efembtn mt-16">
              <button
                className={`small-btn ${
                  commStat == "Communicating" ? "efemactive btn-inactive" : ""
                }`}
                data-channel="CommunicationStateCmd"
                data-value="1"
                onClick={handleButtonClick}
              >
                Enable
              </button>
              <button
                className={`small-btn ${
                  commStat == "Disabled" ? "efemactive btn-inactive" : ""
                }`}
                data-channel="CommunicationStateCmd"
                data-value="0"
                onClick={handleButtonClick}
              >
                Disable
              </button>
            </div>
          </div>

          <div className="flex-1 flex justify-between gap-3 border-2 border-gray-400  rounded-xl px-4 py-4 relative">
            <div className="flex flex-col gap-3 flex-1">
              <div className="mt-1 w-full mb-6">
                <input
                  type="text"
                  disabled
                  value={controlState}
                  className="display-input w-full bg-gray-200 border-gray-300 capitalize h-10 px-1"
                />
              </div>
              <div className="flex justify-between items-center gap-3">
              <div className="border-2 border-gray-400  rounded-xl p-4 relative">
                <div className="bg-blue-100 text-md -mt-9 w-40 font-bold text-center" style={{width: "114px"}}>
                  Control State
                </div>

                <div className="flex md:flex-col lg:flex-row justify-between items-center gap-4 efembtn pt-4">
                  <button
                    className={`small-btn md:w-full lg:w-1/2 ${
                      controlState == "Attempt Online" ||
                      controlState == "Online Remote" ||
                      controlState == "Online Local" ||
                      controlState == "Host Offline"
                        ? "efemactive btn-inactive"
                        : ""
                    }`}
                    data-channel="ControlStateCmd"
                    data-value="1"
                    onClick={handleButtonClick}
                  >
                    Online
                  </button>
                  <button
                    className={`small-btn md:w-full lg:w-1/2 ${
                      controlState == "Equipment Offline"
                        ? "efemactive btn-inactive"
                        : ""
                    }`}
                    data-channel="ControlStateCmd"
                    data-value="0"
                    onClick={handleButtonClick}
                  >
                    Offline
                  </button>
                </div>
              </div>

              <div className="flex-1 border-2 border-gray-400  rounded-xl p-4 relative w-1/2">
                <div className="bg-blue-100 text-md -mt-9 w-48 font-bold text-center" style={{width: "126px"}}>
                  Remote Control
                </div>

                <div className="flex md:flex-col lg:flex-row justify-between items-center gap-4 efembtn mt-0 pt-4">
                  <button
                    className={`small-btn md:w-full lg:w-1/2 ${
                      controlState == "Online Local" ||
                      controlState == "Equipment Offline"
                        ? "efemactive btn-inactive"
                        : controlState == "Host Offline"
                        ? "disabled-gray btn-inactive"
                        : ""
                    }`}
                    data-channel="RemoteControlCmd"
                    data-value={0}
                    onClick={handleButtonClick}
                  >
                    Local
                  </button>
                  <button
                    className={`small-btn md:w-full lg:w-1/2 ${
                      controlState == "Equipment Offline" ||
                      controlState == "Online Remote"
                        ? "efemactive btn-inactive"
                        : controlState == "Host Offline"
                        ? "disabled-gray btn-inactive"
                        : ""
                    }`}
                    data-channel="RemoteControlCmd"
                    data-value={1}
                    onClick={handleButtonClick}
                  >
                    Remote
                  </button>
                </div>
              </div>
              </div>
              
            </div>

            <div className="flex gap-3 flex-1" style={{maxWidth: "600px"}}>
              <div className="flex-1 border-2 border-gray-400  rounded-xl p-4 relative mt-20">
                <div
                  className="text-md bg-blue-100 -mt-9 font-bold px-2"
                  style={{ width: "275px" }}
                >
                  Set Material Present/Absent Event
                </div>

                <div className="flex md:flex-col lg:flex-row md:justify-center lg:justify-between items-center gap-4 efembtn h-full mt-3">
                  <button
                    className={`small-btn md:w-full lg:w-1/2 ${eventStat == "1" ? "active" : ""}`}
                    data-channel="Slot1"
                    data-value="1"
                    onClick={handleButtonClick}
                  >
                    Present
                  </button>
                  <button
                    className={`small-btn md:w-full lg:w-1/2 ${commStat == "0" ? "active" : ""}`}
                    data-channel="Slot1"
                    data-value="0"
                    onClick={handleButtonClick}
                  >
                    Absent
                  </button>
                </div>
              </div>

              <div className="flex-1 border-2 border-gray-400  rounded-xl p-4 relative  mt-20">
                <div
                  className="text-md bg-blue-100 -mt-9 font-bold px-2"
                  style={{ width: "205px" }}
                >
                  Set Alarm &amp; Reset Alarm
                </div>

                <div className="flex md:flex-col lg:flex-row md:justify-center lg:justify-between items-center gap-4 efembtn h-full mt-3">
                  <button
                    className={`small-btn md:w-full lg:w-28 ${commStat == "1" ? "active" : ""}`}
                    data-channel="Alarm.DeviceDisconnected"
                    data-value="1"
                    onClick={handleButtonClick}
                  >
                    Set Alarm
                  </button>
                  <button
                    className={`small-btn md:w-full lg:w-28 ${commStat == "0" ? "active" : ""}`}
                    data-channel="Alarm.DeviceDisconnected"
                    data-value="0"
                    onClick={handleButtonClick}
                  >
                    Reset Alarm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-3 p-5 pt-0 mt-3">
          <div className="flex-1 border-2 border-gray-400  rounded-xl p-4 relative">
            <div className="text-md bg-blue-100 -mt-9 w-44 font-bold text-center" style={{width: "120px"}}>
              Recipe Control
            </div>
            <div className="flex flex-col gap-3 efembtn">
              <div className="flex flex-col gap-2 p-3">
                <label htmlFor="selecFile">Select Recipe for Upload</label>
                <div className="p-2 border border-gray-300 w-full">
                  <input type="file" id="selecFile" />
                </div>
                <div className="flex justify-end">
                  <button className="small-btn">Upload Recipe</button>
                </div>
              </div>

              <div className="flex flex-col gap-2 w">
                <label htmlFor="downloadName">
                  Specify Recipe Name to Download
                </label>
                <div className="flex items-center gap-2">
                  <input
                    type="text"
                    id="downloadName"
                    className="w-48 h-10 border border-black"
                  />
                  <button
                    className="text-xs p-0"
                    style={{
                      padding: "15px 10px",
                      fontSize: "15px",
                      lineHeight: "1.1",
                    }}
                  >
                    Download Recipe
                  </button>
                </div>
              </div>

              <div className="flex flex-col gap-3 mb-2 mt-4 w-full">
                <label>Upload/Download Status</label>
                <input
                  type="text"
                  disabled
                  value=""
                  className="w-full bg-gray-200 border-gray-300 h-10"
                />
              </div>
            </div>
          </div>

          <div className="flex-1 border-2 border-gray-400  rounded-xl p-4  relative">
            <div className="text-md bg-blue-100 -mt-9 w-52 font-bold text-center" style={{width: "144px"}}>
              Terminal Services
            </div>

            <div className="mb-3">
              <div className="flex flex-col justify-between gap-3 mt-3">
                <label htmlFor="messageReceived">
                  Terminal Message Received
                </label>
                <div className="flex-1">
                  <textarea
                    ref={sendMessageFieldRef}
                    rows={3}
                    className="textarea-input w-full border border-black p-2 "
                    id="messageReceived"
                    value={terminalMessageReceived}
                    readOnly
                  ></textarea>
                </div>
              </div>
              <div className="flex justify-end items-center efembtn">
                <button
                  className="small-btn"
                  data-channel="TerminalMessageAckCmd"
                  data-value={1}
                  onClick={handleButtonClick}
                >
                  Acknowledge
                </button>
              </div>
            </div>

            <div className="mb-3">
              <div className="flex flex-col justify-between gap-3 mt-2">
                <label htmlFor="messageSend">Terminal Message Send</label>
                <div className="flex-1">
                  <textarea
                    className="w-full border border-black p-2"
                    id="messageSend"
                    onChange={handleTerminalMessage}
                    style={{ height: "90px" }}
                  ></textarea>
                </div>
              </div>
              <div className="flex justify-end items-center efembtn">
                <button
                  className="small-btn"
                  data-channel="TerminalMessageSendCmd"
                  data-value={terminalMessageSent}
                  onClick={handleButtonClick}
                >
                  Send
                </button>
              </div>
            </div>
          </div>

          <div className="flex-1 border-2 border-gray-400  rounded-xl p-4  relative">
            <div className="text-md bg-blue-100 -mt-9 w-28 font-bold text-center" style={{width: "70px"}}>
              General
            </div>
            <div className="flex flex-col justify-between gap-3 mt-4">
              <div className="efembtn mb-3">
                <button className="small-btn">Are Your There?</button>
              </div>
              <div className="flex items-center gap-2">
                <label className="w-40">Message Sent</label>
                <input
                  type="text"
                  className="p-2 w-2/3 h-10 border border-black"
                />
              </div>
              <div className="flex items-center gap-2">
                <label className="w-40">Message Received</label>
                <input
                  type="text"
                  className="p-2 w-2/3 h-10 border border-black"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
