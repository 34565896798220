import axios from 'axios'

let cachedIpAddress= null

export const getIpAddress = async () => {
  if (cachedIpAddress) {
    return cachedIpAddress
  }

  try {
    const response = await axios.get('https://api.ipify.org?format=json')
    cachedIpAddress = response.data.ip
    return cachedIpAddress
  } catch (error) {
    console.error('Failed to fetch IP address:', error)
    return 'unknown'
  }
}
