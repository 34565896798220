import Alarm from "./alarm";
import Logo from "./logo";
import CommunicationHost from "./communicationHost";
import Alarmpause from "./alarmpause";
import Alarmseveritylevel from "./alarmseveritylevel";
import E95Screendemo from "../../components/Screens/e95/e95Screendemo";
import EventsDisplay from "../../components/Parts/eventsDisplay/eventsDisplay";
import AlarmNotification from "../../components/Parts/alarmNotification/alarmNotification";
import Copilot from "../../components/Parts/copilot/copilot";
import Logout from "../../components/Screens/Auth/logout";


export default { Logo, Alarm, CommunicationHost, Alarmpause , Alarmseveritylevel, E95Screendemo, EventsDisplay, AlarmNotification, Logout,Copilot};