/**
 * Check if a user has permission to access a specific feature
 * @param {string} user - The user details
 * @param {string} permission - The permission to check
 * @returns {boolean} - Whether the user has the permission
 */
function hasPermission(user, permission) {
  const isSuperAdmin = user?.user_type === "super_admin";
  const role_permissions = user?.role_permissions?.[0];
  if (isSuperAdmin) {
    return true;
  }
  if (role_permissions?.admin_access) {
    return true;
  }
  return false;
}


export {  hasPermission };
