import React, { useState, useEffect } from 'react';


const Permission = () => {
  const roles = ['Production Operator', 'Process Engineer', 'Maintenance', 'Supplier / Field Service Engineer'];
  const permissions = [
    { name: 'Filter', subPermissions: ['Contents', 'Status', 'Maintenance', 'Recipes'] },
    { name: 'Goals', subPermissions: ['Administer', 'View', 'Edit', 'Create', 'Delete'] }
  ];

  const [permissionState, setPermissionState] = useState({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    fetchPermissions();
  }, []);

  const fetchPermissions = async () => {
    setLoading(true);
    try {
      // Simulating API call
      const response = await new Promise(resolve => 
        setTimeout(() => resolve({
          data: {
            'Production Operator': { 'Filter.Contents': true, 'Edit.View': true },
            'Process Engineer': { 'Filter.Status': true, 'Edit.Edit': true },
          }
        }), 100)
      );
      setPermissionState(response.data);
    } catch (error) {
      console.error('Error fetching permissions:', error);
      console.log({
        title: "Error",
        description: "Failed to load permissions. Please try again.",
        variant: "destructive",
      });
    }
    setLoading(false);
  };

  const handleCheckboxChange = (role, permission) => {
    setPermissionState(prevState => ({
      ...prevState,
      [role]: {
        ...prevState[role],
        [permission]: !prevState[role]?.[permission]
      }
    }));
  };

  const savePermissions = async () => {
    setSaving(true);
    console.log(permissionState,'permissionState')
    try {
      // Simulating API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      console.log({
        title: "Success",
        description: "Permissions saved successfully!",
      });
    } catch (error) {
      console.error('Error saving permissions:', error);
      console.log({
        title: "Error",
        description: "Failed to save permissions. Please try again.",
        variant: "destructive",
      });
    }
    setSaving(false);
  };

  if (loading) {
    return <div className="text-center py-4">Loading permissions...</div>;
  }

  return (
    <div className="py-1 h-[calc(100vh-264px)] px-20 bg-blue-50 flex gap-4 ">
      <div className="relative flex-1  h-[calc(100vh-278px)] bg-blue-100 overflow-auto rounded-t-lg border border-gray-200">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
        <thead className="text-xs text-gray-700 uppercase bg-[#B6D3EA]  py-1">
          <tr className=' '>
            <th className="sticky -top-[1px] text-left py-2 px-4 border-l border-y border-[#ccc] bg-[#B6D3EA]">Permission</th>
            {roles.map((role, index) => (
              <th key={index} className="text-center sticky -top-[1px] border px-4 py-2  border-[#ccc] bg-[#B6D3EA]">{role}</th>
            ))}
          </tr>
        </thead>
        <tbody> 
          {permissions.map((permission, permIndex) => (
            <React.Fragment key={permIndex}>
              <tr>
                <td className="py-2 px-4 text-black font-semibold bg-[#CEE2F2] border-b border-l border-[#ccc]">{permission.name}</td>
                {roles.map((_, roleIndex) => (
                  <td key={roleIndex} className="py-2 px-4 bg-[#CEE2F2] border-[#ccc]"></td>
                ))}
              </tr>
              {permission.subPermissions.map((subPerm, subIndex) => (
                <tr key={`${permIndex}-${subIndex}`}>
                  <td className="py-2 px-4 pl-8 border border-[#ccc]">{subPerm}</td>
                  {roles.map((role, roleIndex) => (
                    <td key={roleIndex} className="py-2 px-4 text-center border border-[#ccc]">
                      <input 
                        type="checkbox" 
                        className="form-checkbox h-5 w-5 text-blue-600 bg-white border-gray-600 rounded cursor-pointer"
                        checked={!!permissionState[role]?.[`${permission.name}.${subPerm}`]}
                        onChange={() => handleCheckboxChange(role, `${permission.name}.${subPerm}`)}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      </div>
      {/* <button 
        onClick={savePermissions} 
        disabled={saving}
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
      >
        {saving ? 'Saving...' : 'Save Changes'}
      </button> */}
    </div>
    
  );
};

export default Permission;