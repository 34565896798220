
import * as React from "react";

function IconViewModule(props) {
  return (
    <svg
      viewBox="0 0 14 14"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >

<path d="M13.3758 0H0.624204C0.278663 0 0 0.220588 0 0.494118V3.37941C0 3.65294 0.278663 3.87353 0.624204 3.87353H13.3758C13.7213 3.87353 14 3.65294 14 3.37941V0.494118C14 0.220588 13.7213 0 13.3758 0ZM2.16242 2.71765C2.16242 2.85 2.02866 2.95588 1.86146 2.95588H1.18153C1.01433 2.95588 0.880573 2.85 0.880573 2.71765V1.15588C0.880573 1.02353 1.01433 0.917647 1.18153 0.917647H1.86146C2.02866 0.917647 2.16242 1.02353 2.16242 1.15588V2.71765ZM4.32484 2.71765C4.32484 2.85 4.19108 2.95588 4.02388 2.95588H3.34395C3.17675 2.95588 3.04299 2.85 3.04299 2.71765V1.15588C3.04299 1.02353 3.17675 0.917647 3.34395 0.917647H4.02388C4.19108 0.917647 4.32484 1.02353 4.32484 1.15588V2.71765ZM12.8519 2.44412C12.6847 2.57647 12.4618 2.65588 12.2054 2.65588C11.7038 2.65588 11.2914 2.32941 11.2914 1.93235C11.2914 1.53529 11.7038 1.20882 12.2054 1.20882C12.707 1.20882 13.1194 1.53529 13.1194 1.93235C13.1194 2.13529 13.0191 2.31176 12.8519 2.44412ZM13.3758 5.12647H0.624204C0.278663 5.12647 0 5.34706 0 5.62059V8.50588C0 8.77941 0.278663 9 0.624204 9H13.3758C13.7213 9 14 8.77941 14 8.50588V5.62059C14 5.34706 13.7213 5.12647 13.3758 5.12647ZM2.16242 7.84412C2.16242 7.97647 2.02866 8.08235 1.86146 8.08235H1.18153C1.01433 8.08235 0.880573 7.97647 0.880573 7.84412V6.28235C0.880573 6.15 1.01433 6.04412 1.18153 6.04412H1.86146C2.02866 6.04412 2.16242 6.15 2.16242 6.28235V7.84412ZM4.32484 7.84412C4.32484 7.97647 4.19108 8.08235 4.02388 8.08235H3.34395C3.17675 8.08235 3.04299 7.97647 3.04299 7.84412V6.28235C3.04299 6.15 3.17675 6.04412 3.34395 6.04412H4.02388C4.19108 6.04412 4.32484 6.15 4.32484 6.28235V7.84412ZM12.8519 7.57941C12.6847 7.71176 12.4618 7.79118 12.2054 7.79118C11.7038 7.79118 11.2914 7.46471 11.2914 7.06765C11.2914 6.67059 11.7038 6.34412 12.2054 6.34412C12.707 6.34412 13.1194 6.67059 13.1194 7.06765C13.1194 7.26176 13.0191 7.44706 12.8519 7.57941Z" fill="currentColor"/>
<path d="M13.3758 10H0.624204C0.278663 10 0 10.2278 0 10.5103V13.4897C0 13.7722 0.278663 14 0.624204 14H13.3758C13.7213 14 14 13.7722 14 13.4897V10.5103C14 10.2278 13.7213 10 13.3758 10ZM2.16242 12.8064C2.16242 12.9431 2.02866 13.0524 1.86146 13.0524H1.18153C1.01433 13.0524 0.880573 12.9431 0.880573 12.8064V11.1936C0.880573 11.0569 1.01433 10.9476 1.18153 10.9476H1.86146C2.02866 10.9476 2.16242 11.0569 2.16242 11.1936V12.8064ZM4.32484 12.8064C4.32484 12.9431 4.19108 13.0524 4.02388 13.0524H3.34395C3.17675 13.0524 3.04299 12.9431 3.04299 12.8064V11.1936C3.04299 11.0569 3.17675 10.9476 3.34395 10.9476H4.02388C4.19108 10.9476 4.32484 11.0569 4.32484 11.1936V12.8064ZM12.8519 12.5239C12.6847 12.6606 12.4618 12.7426 12.2054 12.7426C11.7038 12.7426 11.2914 12.4055 11.2914 11.9954C11.2914 11.5854 11.7038 11.2483 12.2054 11.2483C12.707 11.2483 13.1194 11.5854 13.1194 11.9954C13.1194 12.205 13.0191 12.3872 12.8519 12.5239Z" fill="currentColor"/>


    </svg>
  );
}

export default IconViewModule;