import { useEffect, useRef, useState } from "react";
import "./E87.css";
import { ReadAllChannelStates, ReadAllChannels } from "../../api/api";

export default function E87(props) {
  const portNames = [
    { value: "p1", label: "LP1" },
    { value: "p2", label: "LP2" },
    { value: "p3", label: "LP3" },
    { value: "p4", label: "LP4" },
    { value: "p5", label: "LP5" },
    { value: "p6", label: "LP6" },
    { value: "p7", label: "LP7" },
    { value: "p8", label: "LP8" },
  ];
  const numbersArray = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 24,
  ];
  const activeData = [
    {
      item1: "",
      item2: "",
    },
    {
      item1: "",
      item2: "",
    },
    {
      item1: "",
      item2: "",
    },
    {
      item1: "",
      item2: "",
    },
    {
      item1: "",
      item2: "",
    },
    {
      item1: "",
      item2: "",
    },
    {
      item1: "",
      item2: "",
    },
    {
      item1: "",
      item2: "",
    },
    {
      item1: "",
      item2: "",
    },
    {
      item1: " ",
      item2: "",
    },
    {
      item1: " ",
      item2: "",
    },
    {
      item1: " ",
      item2: "",
    },
    {
      item1: " ",
      item2: "",
    },
    {
      item1: " ",
      item2: "",
    },
  ];
  const [selectedRadioTabValue, setSelectedRadioTabValue] = useState("e87_tab-1");
  const [selectedPort, setSelectedPort] = useState("p1");
  const [allObjects, setAllObjects] = useState({
    LP1_CarrierID: {
      ioObject: "E87",
      channelName: "LP1.CarrierID",
      channelLabel: "LP1_CarrierID",
      value: "",
    },
    LP1_AssociationStatus: {
      ioObject: "E87",
      channelName: "LP1.AssociationStatus",
      channelLabel: "LP1_AssociationStatus",
      value: "",
    },
    LP1_ServiceCmd: {
      ioObject: "E87",
      channelName: "LP1.ServiceCmd",
      channelLabel: "LP1_ServiceCmd",
      value: "",
    },
    LP1_AccessCmd: {
      ioObject: "E87",
      channelName: "LP1.AccessCmd",
      channelLabel: "LP1_AccessCmd",
      value: "",
    },
    LP1_PlaceCmd: {
      ioObject: "E87",
      channelName: "LP1.PlaceCmd",
      channelLabel: "LP1_PlaceCmd",
      value: "",
    },
    LP1_RemoveCmd: {
      ioObject: "E87",
      channelName: "LP1.RemoveCmd",
      channelLabel: "LP1_RemoveCmd",
      value: "",
    },
    LP1_UndockCmd: {
      ioObject: "E87",
      channelName: "LP1.UndockCmd",
      channelLabel: "LP1_UndockCmd",
      value: "",
    },
    LP1_ProceedCarrierCmd: {
      ioObject: "E87",
      channelName: "LP1.ProceedCarrierCmd",
      channelLabel: "LP1_ProceedCarrierCmd",
      value: "",
    },
    LP1_CarrierCancelCmd: {
      ioObject: "E87",
      channelName: "LP1.CarrierCancelCmd",
      channelLabel: "LP1_CarrierCancelCmd",
      value: "",
    },
    LP1_CarrierCancelAtPortCmd: {
      ioObject: "E87",
      channelName: "LP1.CarrierCancelAtPortCmd",
      channelLabel: "LP1_CarrierCancelAtPortCmd",
      value: "",
    },
    LP1_ReserveAtPortCmd: {
      ioObject: "E87",
      channelName: "LP1.ReserveAtPortCmd",
      channelLabel: "LP1_ReserveAtPortCmd",
      value: "",
    },
    LP1_BindCmd: {
      ioObject: "E87",
      channelName: "LP1.BindCmd",
      channelLabel: "LP1_BindCmd",
      value: "",
    },
    LP1_CancelAllCarrierOutCmd: {
      ioObject: "E87",
      channelName: "LP1.CancelAllCarrierOutCmd",
      channelLabel: "LP1_CancelAllCarrierOutCmd",
      value: "",
    },
    LP1_CarrierReCreateCmd: {
      ioObject: "E87",
      channelName: "LP1.CarrierReCreateCmd",
      channelLabel: "LP1_CarrierReCreateCmd",
      value: "",
    },
  });
  const [channeldata, setChanneldata] = useState([]);
  const [carrierId, setCarrierId] = useState("");
  const [associationStatus, setAssociationStatus] = useState("");
  const [transferPortStatus, setTransferPortStatus] = useState("");
  const [reservationStatus, setReservationStatus] = useState("");
  const [serviceStatus, setServiceStatus] = useState("");
  const [accessModeStatus, setAccessModeStatus] = useState("");
  const [carrierAccessStatus, setCarrierAccessStatus] = useState("");
  const [carrierIdVerificationStatus, setCarrierIdVerificationStatus] =useState("");
  const [carrierSlotMapStatus, setCarrierSlotMapStatus] = useState("");
  const [carrierCapacity, setCarrierCapacity] = useState("");
  const [numberofWafers, setNumberofWafers] = useState("");
  const [tableObjects, setTableObjects] = useState([
    {
      ioObject: "E87",
      channelName: "LP1.Slot1.Id",
      channelLabel: "LP1_Slot1_Id",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot2.Id",
      channelLabel: "LP1_Slot2_Id",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot3.Id",
      channelLabel: "LP1_Slot3_Id",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot4.Id",
      channelLabel: "LP1_Slot4_Id",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot5.Id",
      channelLabel: "LP1_Slot5_Id",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot6.Id",
      channelLabel: "LP1_Slot6_Id",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot7.Id",
      channelLabel: "LP1_Slot7_Id",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot8.Id",
      channelLabel: "LP1_Slot8_Id",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot9.Id",
      channelLabel: "LP1_Slot9_Id",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot10.Id",
      channelLabel: "LP1_Slot10_Id",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot11.Id",
      channelLabel: "LP1_Slot11_Id",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot12.Id",
      channelLabel: "LP1_Slot12_Id",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot13.Id",
      channelLabel: "LP1_Slot13_Id",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot14.Id",
      channelLabel: "LP1_Slot14_Id",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot15.Id",
      channelLabel: "LP1_Slot15_Id",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot16.Id",
      channelLabel: "LP1_Slot16_Id",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot17.Id",
      channelLabel: "LP1_Slot17_Id",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot18.Id",
      channelLabel: "LP1_Slot18_Id",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot19.Id",
      channelLabel: "LP1_Slot19_Id",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot20.Id",
      channelLabel: "LP1_Slot20_Id",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot21.Id",
      channelLabel: "LP1_Slot21_Id",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot22.Id",
      channelLabel: "LP1_Slot22_Id",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot23.Id",
      channelLabel: "LP1_Slot23_Id",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot24.Id",
      channelLabel: "LP1_Slot24_Id",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot25.Id",
      channelLabel: "LP1_Slot25_Id",
      value: "",
    },
  ]);
  const [tableStatusObjects, setTableStausObjects] = useState([
    {
      ioObject: "E87",
      channelName: "LP1.Slot1.SlotStatus",
      channelLabel: "LP1_Slot1_SlotStatus",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot2.SlotStatus",
      channelLabel: "LP1_Slot2_SlotStatus",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot3.SlotStatus",
      channelLabel: "LP1_Slot3_SlotStatus",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot4.SlotStatus",
      channelLabel: "LP1_Slot4_SlotStatus",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot5.SlotStatus",
      channelLabel: "LP1_Slot5_SlotStatus",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot6.SlotStatus",
      channelLabel: "LP1_Slot6_SlotStatus",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot7.SlotStatus",
      channelLabel: "LP1_Slot7_SlotStatus",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot8.SlotStatus",
      channelLabel: "LP1_Slot8_SlotStatus",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot9.SlotStatus",
      channelLabel: "LP1_Slot9_SlotStatus",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot10.SlotStatus",
      channelLabel: "LP1_Slot10_SlotStatus",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot11.SlotStatus",
      channelLabel: "LP1_Slot11_SlotStatus",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot12.SlotStatus",
      channelLabel: "LP1_Slot12_SlotStatus",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot13.SlotStatus",
      channelLabel: "LP1_Slot13_SlotStatus",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot14.SlotStatus",
      channelLabel: "LP1_Slot14_SlotStatus",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot15.SlotStatus",
      channelLabel: "LP1_Slot15_SlotStatus",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot16.SlotStatus",
      channelLabel: "LP1_Slot16_SlotStatus",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot17.SlotStatus",
      channelLabel: "LP1_Slot17_SlotStatus",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot18.SlotStatus",
      channelLabel: "LP1_Slot18_SlotStatus",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot19.SlotStatus",
      channelLabel: "LP1_Slot19_SlotStatus",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot20.SlotStatus",
      channelLabel: "LP1_Slot20_SlotStatus",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot21.SlotStatus",
      channelLabel: "LP1_Slot21_SlotStatus",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot22.SlotStatus",
      channelLabel: "LP1_Slot22_SlotStatus",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot23.SlotStatus",
      channelLabel: "LP1_Slot23_SlotStatus",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot24.SlotStatus",
      channelLabel: "LP1_Slot24_SlotStatus",
      value: "",
    },
    {
      ioObject: "E87",
      channelName: "LP1.Slot25.SlotStatus",
      channelLabel: "LP1_Slot25_SlotStatus",
      value: "",
    },
  ]);
  const [openModal, setOpenModal] = useState(false);
  const handleRadioTabChange = (e) => {
    setSelectedRadioTabValue(e.target.value);
  };

  const handlePortChange = (e) => {
    setSelectedPort(e);
  };

  const handleButtonClick = (e) => {
    const elm = e.target;
    const name = elm.getAttribute("data-name");
    const value = elm.getAttribute("data-value");

    console.log("name Click", name);
    console.log("value Click", value);

    const obj = allObjects[name];
    console.log("allObjects Click", obj);

    if (obj) {
      obj.value = value;
      const channel = setChannelData(obj);
      handleCommand(channel);
    }
  };
  const setChannelData = (data) => {
    console.log("datadatadata", data);
    console.log("channeldata", channeldata);
    const channel = channeldata.find((obj) => {
      return (
        obj.ioObject === data.ioObject && obj.channelName === data.channelName
      );
    });
    if (channel) {
      channel.data.value = data.value;
    }
    console.log("channel 1", channel);

    return channel;
  };
  //   const callNotification = (texts, color) => {
  //     notifications.showNotification({
  //       title: texts,
  //       message: '',
  //       autoClose: 2000,
  //       color: color,
  //       loading: false,
  //       disallowClose: false,
  //     })
  //   }

  const handleCommand = async (data) => {
    console.log("data", data);
    // const { error } = await UpdateIoData(data)

    // if (!error) {
    //   callNotification('Write Successfully!', 'lime')
    // } else {
    //   callNotification('Please check the entered value!', 'red')
    // }
  };

  const generateObject = (slotNumber) => {
    return {
      ioObject: "E87",
      channelName: `LP1.Slot${slotNumber}.Id`,
      channelLabel: `LP1_Slot${slotNumber}_Id`,
      value: "",
    };
  };
  const setTableData = () => {
    const obj = [];
    for (let i = 1; i <= 25; i++) {
      obj.push(generateObject(i));
    }

    console.log("objobjobjobj", obj);

    if (obj.length > 0) {
      setTableObjects(obj);
    }
  };

  const generateStatusObject = (slotNumber) => {
    return {
      ioObject: "E87",
      channelName: `LP1.Slot${slotNumber}.SlotStatus`,
      channelLabel: `LP1_Slot${slotNumber}_SlotStatus`,
      value: "",
    };
  };
  const setStatusTableData = () => {
    const obj = [];
    for (let i = 1; i <= 25; i++) {
      obj.push(generateStatusObject(i));
    }

    console.log("SlotStatus objobjobjobj", obj);

    if (obj.length > 0) {
      setTableStausObjects(obj);
    }
  };
  const ioChannels = useRef([]);
  const refreshChannels = async () => {
    const channelStates = await ReadAllChannelStates();
    channelStates.data.data.forEach((channel) => {
      const index = ioChannels.current.findIndex((c) => c.id === channel.id);
      if (index >= 0) {
        ioChannels.current[index] = channel;
      }
    });
    const res = ioChannels.current;
    const stat = false;
    const sortedSlotval = res.sort((a, b) => {
      const channelNameA = a.channelName;
      const channelNameB = b.channelName;
      // Extract numeric parts from channelName
      const numA = parseInt(channelNameA.match(/\d+/)?.[0]) || 0;
      const numB = parseInt(channelNameB.match(/\d+/)?.[0]) || 0;
      // Compare numeric parts
      if (numA < numB) {
        return -1;
      }
      if (numA > numB) {
        return 1;
      }
      // If numeric parts are equal, compare entire channelName strings
      if (channelNameA < channelNameB) {
        return -1;
      }
      if (channelNameA > channelNameB) {
        return 1;
      }
      return 0;
    });
    setChanneldata(sortedSlotval);
    res?.map((i, j) =>
      i?.channelName === "LP1.CarrierID"
        ? setCarrierId(i.data?.value)
        : i?.channelName === "LP1.AssociationStatus"
        ? setAssociationStatus(i.data?.value)
        : i?.channelName === "LP1.TransferPortStatus"
        ? setTransferPortStatus(i.data?.value)
        : i?.channelName === "LP1.ReservationStatus"
        ? setReservationStatus(i.data?.value)
        : i?.channelName === "LP1.ServiceStatus"
        ? setServiceStatus(i.data?.value)
        : i?.channelName === "LP1.AccessModeStatus"
        ? setAccessModeStatus(i.data?.value)
        : i?.channelName === "LP1.AccessingStatus"
        ? setCarrierAccessStatus(i.data?.value)
        : i?.channelName === "LP1.IDVerificationStatus"
        ? setCarrierIdVerificationStatus(i.data?.value)
        : i?.channelName === "LP1.SlotMapVerificationStatus"
        ? setCarrierSlotMapStatus(i.data?.value)
        : i?.channelName === "LP1.CarrierCapacity"
        ? setCarrierCapacity(i.data?.value)
        : i?.channelName === "LP1.NumberofWafers"
        ? setNumberofWafers(i.data?.value)
        : ""
    );
    // setBlink(stat)
  };
  const fetchIOChannels = async () => {
    const result = await ReadAllChannels();
    console.log("fetchIOChannels result", result);
    const channels = result.data.data;
    channels.sort((a, b) => (a.channelName > b.channelName ? 1 : -1));
    ioChannels.current = channels;
    // createFilterOptions(channels)

    await refreshChannels();
  };


  const filterTableSlotData = () => {
    const availableSlots = [];
    const filterSlots = (channelName) => {
      console.log("channelNamechannelNamechannelName", channelName);
      const findChannelData = channeldata.filter(
        (item) => item.channelName == channelName
      );
      return findChannelData;
    };

    for (let i = 1; i < 26; i++) {
      const filtered = filterSlots(`LP1.Slot${i}.Id`);

      console.log("filtered slot id", filtered);

      if (filtered.length > 0) {
        console.log("filteredfilteredfilteredfiltered SLOT", filtered);
        tableObjects.filter((item) => {
          console.log("item.channelName", item.channelName);
          console.log("filtered[0].channelName", filtered[0].channelName);
        });
        console.log("tableObjects]]]]]", tableObjects);
        const filteredSlots = tableObjects.filter(
          (item) => item.channelName == filtered[0].channelName
        );
        console.log("filteredSlot>>>>>", filteredSlots);
        if (filteredSlots.length > 0) {
          console.log("filteredSlots===", filteredSlots);
          const index = tableObjects.findIndex(
            (x) => x.channelName === filtered[0].channelName
          );

          if (index !== -1) {
            availableSlots.push({
              ioObject: "E87",
              channelName: filtered[0].channelName,
              channelLabel: filtered[0].channelLabel,
              value: filtered[0].data.value,
            });

            setTableObjects(availableSlots);
            console.log("tableObjects", tableObjects);
          }
        }
      }
    }
  };

  const filterTableStatusData = () => {
    const availableSlots = [];
    const filterSlots = (channelName) => {
      const findChannelData = channeldata.filter(
        (item) => item.channelName == channelName
      );
      return findChannelData;
    };

    for (let i = 1; i < 26; i++) {
      const filtered = filterSlots(`LP1.Slot${i}.SlotStatus`);

      if (filtered.length > 0) {
        console.log("filteredfilteredfilteredfiltered", filtered);
        const filteredSlots = tableStatusObjects.filter(
          (item) => item.channelName == filtered[0].channelName
        );
        if (filteredSlots.length > 0) {
          const index = tableStatusObjects.findIndex(
            (x) => x.channelName === filtered[0].channelName
          );

          if (index !== -1) {
            console.log(
              "filtered[0].data.value",
              filtered[0].data.value.toString()
            );
            tableStatusObjects[index].value = filtered[0].data.value.toString();

            availableSlots.push({
              ioObject: "E87",
              channelName: filtered[0].channelName,
              channelLabel: filtered[0].channelLabel,
              value: filtered[0].data.value,
            });

            setTableStausObjects(availableSlots);
          }
        }
      }
    }
  };
//   useEffect(() => {
//     if (channeldata.length > 0) {
//       filterTableSlotData();
//       filterTableStatusData();
//     }
//   }, [channeldata]);

//   useEffect(() => {
//     setTableData();
//     setStatusTableData();
//     const fetchData = async () => {
//       await fetchIOChannels();
//     };
//     fetchData();
//     const intervalId = setInterval(() => {
//       refreshChannels();
//     }, 2000);
//     return () => clearInterval(intervalId);
//   }, []);
  return (
    <div className="popup e-87-wrapper">
      <div className="popbox">
        <div className="e87_tabs border-b third-step-tabs mx-3 mt-3">
          <div className="tab_button-radio">
            <input
              type="radio"
              className="left-skew"
              name="e87-1"
              checked={selectedRadioTabValue === "e87_tab-1"}
              value="e87_tab-1"
              onChange={handleRadioTabChange}
              id="e87_tab-1"
            />
            <label className="btn btn-default" htmlFor="e87_tab-1">
              Port Management
            </label>
          </div>
          <div className="tab_button-radio">
            <input
              type="radio"
              name="e87-2"
              value="e87_tab-2"
              checked={selectedRadioTabValue === "e87_tab-2"}
              onChange={handleRadioTabChange}
              id="e87_tab-2"
            />
            <label className="btn btn-default" htmlFor="e87_tab-2">
              Carrier Information
            </label>
          </div>
        </div>

        {selectedRadioTabValue === "e87_tab-1" ? (
          <div
            className="e87-wrapper port-management popcontent flex"
            style={{ maxWidth: "1520px" }}
          >
            <div className="tablescroll flex-1 flex flex-col p-6 tab_content bg-gray-100">
              <div className="flex gap-5 md:flex-wrap xl:flex-nowrap">
                <div className="flex flex-col gap-3 sm:w-full md:w-full lg:w-full xl:w-2/4 p-5 border-2 border-gray-400 border-appcolor-light9 rounded-xl">
                  <div className="flex items-center justify-between w-full">
                    <div className="w-3/6">Port Name</div>
                    <div className="flex-1 flex justify-end port-name-select">
                      <select
                        className="border border-gray-200 rounded-md text-xs w-[207px]"
                        placeholder="Port Name"
                        data={portNames}
                        value={selectedPort}
                        onChange={(e) => handlePortChange(e)}
                        disabled
                      >
                        {/* <option>LP1</option> */}
                      </select>
                    </div>
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <div className="w-3/6">Carrier ID</div>
                    <div className="flex-1 flex justify-end">
                      <input
                        type="text"
                        name="LP1_CarrierID"
                        value={carrierId}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <div className="w-3/6">Carrier Capacity</div>
                    <div>
                      <input type="text" value={carrierCapacity} readOnly />
                    </div>
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <div className="w-3/6">Number of Wafers</div>
                    <div>
                      <input type="text" value={numberofWafers} readOnly />
                    </div>
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <div className="w-3/6">Port Association State</div>
                    <div>
                      <input
                        type="text"
                        name="LP1_AssociationStatus"
                        value={associationStatus}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <div className="w-3/6">Port Transfer State</div>
                    <div>
                      <input type="text" value={transferPortStatus} readOnly />
                    </div>
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <div className="w-3/6">Reservation State</div>
                    <div>
                      <input type="text" value={reservationStatus} readOnly />
                    </div>
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <div className="w-3/6">Service State</div>
                    <div>
                      <input type="text" value={serviceStatus} readOnly />
                    </div>
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <div className="w-3/6">Access Mode</div>
                    <div>
                      <input type="text" value={accessModeStatus} readOnly />
                    </div>
                  </div>

                  <div className="flex items-center justify-between w-full">
                    <div className="w-3/6">Carrier Access Status</div>
                    <div>
                      <input type="text" value={carrierAccessStatus} readOnly />
                    </div>
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <div className="w-3/6">Carrier ID Verification Status</div>
                    <div>
                      <input
                        type="text"
                        value={carrierIdVerificationStatus}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <div className="w-3/6">Carrier Slot Map Status</div>
                    <div>
                      <input
                        type="text"
                        value={carrierSlotMapStatus}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-initial flex flex-col gap-3 sm:w-full md:w-full lg:w-full xl:w-2/4">
                  <div className="controls-holder flex flex-col gap-3 p-5 border-2 border-gray-400 border-appcolor-light9 rounded-xl grid-btns">
                    <div className="flex items-center w-full">
                      <div className="w-2/5">Port Transfer</div>
                      <div className="flex items-center gap-3 w-3/5">
                        <button
                          className={`e-87 w-1/2 md:text-xs lg:text-xs ${
                            serviceStatus == "1" ? "active" : ""
                          }`}
                          data-value="1"
                          data-name="LP1_ServiceCmd"
                          onClick={handleButtonClick}
                        >
                          In Service
                        </button>
                        <button
                          className={`e-87 w-1/2 md:text-xs lg:text-xs ${
                            serviceStatus == "2" ? "active" : ""
                          }`}
                          data-value="2"
                          data-name="LP1_ServiceCmd"
                          onClick={handleButtonClick}
                        >
                          Out of Service
                        </button>
                      </div>
                    </div>
                    <div className="flex items-center w-full">
                      <div className="w-2/5">Reservation State</div>
                      <div className="flex items-center gap-3 w-3/5">
                        <button
                          className="e-87 w-1/2"
                          data-value="1"
                          data-name="LP1_ReserveAtPortCmd"
                          onClick={handleButtonClick}
                        >
                          Reserve
                        </button>
                        <button
                          className="e-87 w-1/2"
                          data-value="0"
                          data-name="LP1_ReserveAtPortCmd"
                          onClick={handleButtonClick}
                        >
                          UnReserve
                        </button>
                      </div>
                    </div>
                    <div className="flex items-center w-full">
                      <div
                        className="w-2/5"
                        data-value="1"
                        data-name="LP1_AccessCmd"
                      >
                        Access Mode
                      </div>
                      <div className="flex items-center gap-3 w-3/5">
                        <button
                          className={`e-87 w-1/2 ${
                            accessModeStatus == "1" ? "active" : ""
                          }`}
                          data-value="1"
                          data-name="LP1_AccessCmd"
                          onClick={handleButtonClick}
                        >
                          Auto
                        </button>
                        <button
                          className={`e-87 w-1/2 ${
                            accessModeStatus == "2" ? "active" : ""
                          }`}
                          data-value="2"
                          data-name="LP1_AccessCmd"
                          onClick={handleButtonClick}
                        >
                          Manual
                        </button>
                      </div>
                    </div>
                    <div className="flex items-center w-full mt-3">
                      <div className="flex items-center gap-3 w-full">
                        <button
                          className="e-87 w-1/2"
                          data-value="1"
                          data-name="LP1_PlaceCmd"
                          onClick={handleButtonClick}
                        >
                          Place
                        </button>
                        <button
                          className="e-87 w-1/2"
                          data-value="1"
                          data-name="LP1_RemoveCmd"
                          onClick={handleButtonClick}
                        >
                          Remove
                        </button>
                        <button
                          className="e-87 w-1/2"
                          data-value="1"
                          data-name="LP1_UndockCmd"
                          onClick={handleButtonClick}
                        >
                          Undock
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="table-holder">
                    <h2 className="font-bold">Slot Map Status</h2>

                    <div className="flex flex-col p-0 mt-2 no-scroll">
                      <div className="w-full">
                        <table className="slots-table table-auto w-full bg-white rounded-t-xl overflow-hidden shadow-lg text-left">
                          <thead className="bg-gradient-to-r from-black to-gray-700 text-white ">
                            <tr>
                              <th className="py-2 px-2 border-l-5 border-black">
                                Slot No
                              </th>
                              <th className="py-2 px-2 border-l-5 border-black">
                                Status
                              </th>
                            </tr>
                          </thead>
                        </table>
                      </div>

                      <div className="w-full slot-table-body-wrapper overflow-y-auto rounded-b-xl border-4 border-appcolor-light9 border-t-0">
                        <table className="slots-table table-auto w-full bg-white rounded-b-xl shadow-lg text-left">
                          <tbody>
                            {numbersArray.map((num,i) => {
                              return (
                                <tr className="tableoptionshow hover:bg-primary-500 transition-all border-b">
                                  <td className="p-2 border-r border-gray-300 whitespace-nowrap">
                                    {tableObjects[num]?.value}
                                    {/* {i+1} */}
                                  </td>
                                  <td className="p-2 border-r border-gray-300 whitespace-nowrap">
                                    {tableStatusObjects[num]?.value}
                                    {/* CORRECTLY_OCCUPIED */}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-gray-3 flex  flex-col gap-1 p-4 side-button-wrapper">
              <button className="e-87" type="button">
                Control
              </button>
              <button
                className="e-87"
                type="button"
                data-value="1"
                data-name="LP1_BindCmd"
                onClick={handleButtonClick}
              >
                Bind
              </button>
              <button
                className="e-87"
                type="button"
                data-value="0"
                data-name="LP1_BindCmd"
                onClick={handleButtonClick}
              >
                Cancel Bind
              </button>
              <button
                className="e-87"
                type="button"
                data-value="1"
                data-name="LP1_CarrierReCreateCmd"
                onClick={handleButtonClick}
              >
                Carrier Recreate
              </button>
              <button
                type="button"
                className="e-87"
                data-value="1"
                data-name="LP1_ProceedCarrierCmd"
                onClick={handleButtonClick}
              >
                Proceed With Carrier
              </button>
              <button
                type="button"
                className="e-87"
                data-value="1"
                data-name="LP1_CarrierCancelCmd"
                onClick={handleButtonClick}
              >
                Cancel Carrier
              </button>
              <button
                type="button"
                className="e-87"
                data-value="1"
                data-name="LP1_CarrierCancelAtPortCmd"
                onClick={handleButtonClick}
              >
                Cancel Carrier At Port
              </button>
              <button
                type="button"
                className="e-87"
                data-value="1"
                data-name="LP1_CancelAllCarrierOutCmd "
                onClick={handleButtonClick}
              >
                Cancel All Carrier Out
              </button>
            </div>
          </div>
        ) : (
          ""
        )}

        {selectedRadioTabValue === "e87_tab-2" ? (
          <div className="e87-wrapper e-87-carriert-info popcontent flex">
            <div className="tablescroll flex-1 flex flex-col p-6 tab_content bg-gray-100">
              <div
                className="e87-wrapper port-management popcontent flex"
                style={{ maxWidth: "1520px" }}
              >
                <div
                  className="tablescroll flex-0 flex flex-col p-0 tab_content bg-gray-100 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3"
                  style={{ flexGrow: "unset" }}
                >
                  <div className="flex gap-5 md:flex-wrap xl:flex-nowrap">
                    <div className="flex-initial flex flex-col gap-3">
                      <div className="table-holder">
                        {/* <h2 className="main-title font-bold">
                      Carrier Information
                    </h2> */}
                        <div className="flex flex-col p-0 mt-2 no-scroll">
                          <div className="w-full">
                            <table className="slots-table table-auto w-full bg-white rounded-xl overflow-hidden shadow-lg text-left">
                              <thead className="bg-gradient-to-r from-black to-gray-700 text-white ">
                                <tr>
                                  <th className="py-2 px-2 pb-3 border-l-5 border-black">
                                    Carrier
                                  </th>
                                  <th className="py-2 px-2 pb-3 border-l-5 border-black">
                                    Port ID
                                  </th>
                                </tr>
                              </thead>
                            </table>
                          </div>

                          <div className="w-full slot-table-body-wrapper overflow-y-auto rounded-b-xl border-4 border-appcolor-light9 -mt-2 bg-white relative z-0">
                            <table className="slots-table table-auto w-full bg-white rounded-xl shadow-lg text-left">
                              <tbody>
                                {activeData.map((num, index) => {
                                  return (
                                    <tr
                                      key={num.item1 + "-" + num}
                                      className="tableoptionshow hover:bg-primary-500 transition-all border-b"
                                    >
                                      <td className="p-2 border-r border-gray-300 whitespace-nowrap">
                                        {num.item1}
                                      </td>
                                      <td className="p-2 border-r border-gray-300 whitespace-nowrap">
                                        {num.item2}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="bg-gray-3 flex  flex-col gap-1 p-4  editoptions side-button-wrapper"
                  style={{ marginTop: "50px" }}
                >
                  <button
                    className="e-87 btn-big"
                    type="button"
                    onClick={() => setOpenModal(true)}
                  >
                    Refresh
                  </button>
                  <button className="e-87 btn-big" type="button">
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
