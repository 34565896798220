
import * as React from "react";

function IconConfig(props) {
  return (
    <svg
      viewBox="0 0 35 39"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >

<path d="M17.3734 23.7188C21.3481 23.7188 24.5701 20.6757 24.5701 16.9219C24.5701 13.1681 21.3481 10.125 17.3734 10.125C13.3988 10.125 10.1768 13.1681 10.1768 16.9219C10.1768 20.6757 13.3988 23.7188 17.3734 23.7188Z" fill="#bfd4e8" stroke="#656668" stroke-width="1.5"/>
<path d="M31.2042 32.625C32.4377 32.625 33.4376 31.6806 33.4376 30.5156C33.4376 29.3506 32.4377 28.4062 31.2042 28.4062C29.9707 28.4062 28.9707 29.3506 28.9707 30.5156C28.9707 31.6806 29.9707 32.625 31.2042 32.625Z" fill="#656668" stroke="#656668" stroke-width="1.5"/>
<path d="M12.1622 12L8.19158 8.25M23.0813 12L28.5408 6.84375M22.0886 21.375L31.0225 29.8125M12.6585 22.3125L5.70996 28.875M18.118 24.1875V31.2188" stroke="#656668" stroke-width="1.5"/>
<path d="M28.656 7.78125C30.7119 7.78125 32.3784 6.20725 32.3784 4.26562C32.3784 2.324 30.7119 0.75 28.656 0.75C26.6002 0.75 24.9336 2.324 24.9336 4.26562C24.9336 6.20725 26.6002 7.78125 28.656 7.78125Z" fill="#656668" stroke="#656668" stroke-width="1.5"/>
<path d="M18.1178 38.25C20.0366 38.25 21.5921 36.7809 21.5921 34.9688C21.5921 33.1566 20.0366 31.6875 18.1178 31.6875C16.199 31.6875 14.6436 33.1566 14.6436 34.9688C14.6436 36.7809 16.199 38.25 18.1178 38.25Z" fill="#656668" stroke="#656668" stroke-width="1.5"/>
<path d="M6.45439 9.65625C7.962 9.65625 9.18417 8.50198 9.18417 7.07812C9.18417 5.65427 7.962 4.5 6.45439 4.5C4.94677 4.5 3.72461 5.65427 3.72461 7.07812C3.72461 8.50198 4.94677 9.65625 6.45439 9.65625Z" fill="#656668" stroke="#656668" stroke-width="1.5"/>
<path d="M3.97271 32.625C5.20622 32.625 6.20617 31.6806 6.20617 30.5156C6.20617 29.3506 5.20622 28.4062 3.97271 28.4062C2.73921 28.4062 1.73926 29.3506 1.73926 30.5156C1.73926 31.6806 2.73921 32.625 3.97271 32.625Z" fill="#656668" stroke="#656668" stroke-width="1.5"/>
</svg>
);
}

export default IconConfig;