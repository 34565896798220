import React from "react";
import Header from "../../Parts/headerComponents/header";
import Footer from "../../Parts/footerComponents/footer";
import TabNav from "../../Parts/tabNav/tabNav";
import { Link, useLocation } from "react-router-dom";

const iec_url = process.env.REACT_APP_VIA_GASBOX;
// // const iec_url = "http://localhost:3000/iec";
export default function Iecnew(props) {
  
  const location = useLocation();
  return (
    <div>
      {location.pathname!="/" &&<Header layoutdesign={props.layoutdesign} />}
      <div className="px-20 flex gap-4 middle-screen">
        <div className="flex-1 overflow-hidden">
         {location.pathname!="/" && <TabNav tabs={props.layoutdesign.tabs} type="screens"></TabNav>}
          <div className="bg-blue-100 pt-0 overflow-hidden">
            <iframe
              title="IEC"
              src={iec_url+'/iecChamber'}
              className="w-full h-3/4 iec-iframe"
            ></iframe>
          </div>
        </div>
      </div>
      {location.pathname!="/" &&<Footer layoutdesign={props.layoutdesign} />}
    </div>
  );
}
