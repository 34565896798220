import { createContext, useCallback, useContext } from "react";
import { useAuth } from "./AuthContext"
import { UserLogEvent } from "../components/api/user";

// Define event types
export const EVENT_TYPES = {
    CLOSE_USER_CREATION_POPUP: 'CLOSE_USER_CREATION_POPUP',
    OPEN_USER_CREATION_POPUP: 'OPEN_USER_CREATION_POPUP',
    CLOSE_PASSWORD_CHANGE_POPUP: 'CLOSE_USER_CREATION_POPUP',
    OPEN_PASSWORD_CHANGE_POPUP: 'OPEN_USER_CREATION_POPUP',
  };
  
  // Define event details
  const EVENT_DETAILS = {
    [EVENT_TYPES.CLOSE_USER_CREATION_POPUP]: {
      page: 'user admin screen',
      section: 'user creation popup',
      message: 'closed the user creation popup',
    },
    [EVENT_TYPES.OPEN_USER_CREATION_POPUP]: {
      page: 'user admin screen',
      section: 'user creation popup',
      message: 'opened the user creation popup',
    },
    [EVENT_TYPES.CLOSE_PASSWORD_CHANGE_POPUP]: {
        page: 'user admin screen',
        section: 'password change popup',
        message: 'closed the password change popup',
      },
      [EVENT_TYPES.OPEN_PASSWORD_CHANGE_POPUP]: {
        page: 'user admin screen',
        section: 'password change popup',
        message: 'opened the password change popup',
      },
  };

  const LoggingContext = createContext(null)

export const LoggingProvider = ({children}) => {
    const {user} = useAuth();

    const log = useCallback( async(eventType,additionalContent={}) =>{
        if(!EVENT_DETAILS[eventType]){
            console.error('Invalid event type:' ,eventType);
            return
        }

        const {page,section,message}=EVENT_DETAILS[eventType];
        
        const logData = {
            user_id: user?.id, // Use user ID from AuthContext
            page,
            section,
            message,
            content: additionalContent,
          };
          try {
            await UserLogEvent(logData);
            console.log('Event logged successfully:', logData);
          } catch (error) {
            console.error('Failed to log event:', error);
          }
    },[user]);

    return (
        <LoggingContext.Provider value={log}>
          {children}
        </LoggingContext.Provider>
      );
}

export const useLogging = () => {
    const log = useContext(LoggingContext);
    if(!log){
        throw new Error('useLogging must be used within a LoggingProvider')
    }
    return log;
}