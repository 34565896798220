

import * as React from "react";

function IconOpenArrow(props) {
  return (
    <svg
      viewBox="0 0 9 12"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >

<path d="M9.52234 8.3828C9.62432 9.0499 9.04982 9.6244 8.38272 9.52242L1.56082 8.4796C0.750687 8.35576 0.425316 7.36348 1.00482 6.78397L6.78389 1.0049C7.3634 0.425396 8.35568 0.750767 8.47952 1.5609L9.52234 8.3828Z" fill="currentColor"/>
</svg>
);
}

export default IconOpenArrow;


