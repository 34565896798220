import React, { useState } from 'react';
import E95Wafer from '../../../../Images/svg/e95Wafer';
import { ChannelService } from '@getvia/sdk';

  export default function SlotClosed(props) { 




    return (    <>
      <svg width="101" height="8" viewBox="0 0 101 8" x={props.x} y={props.y} fill="none" xmlns="http://www.w3.org/2000/svg" 
       onClick={()=>props.optionmodel(props.e95options)}>
        <g  transform={`rotate(${props.rotate} 50 50)`}>
        
        <rect width="100.54" height="7.08269" rx="2" transform="matrix(-0.999997 -0.00237102 -0.0147797 -0.999891 100.751 7.5303)" fill="#b7b8b8"/>
        </g>
        <defs>
        
        </defs>
      </svg>
      

      </>)
  }