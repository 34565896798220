import React from 'react';
import { useNavigate } from 'react-router-dom';

  export default function Pm2(props) { 
    const navigate = useNavigate();
    const handleClick = () => {
      navigate('/dicingRIEChamber');
    };
    return (
      <svg width="177" height="181" viewBox="0 0 177 181" fill="none" x={props.x} y={props.y}   onClick={handleClick}>
      <path d="M9.57444 75.7386L87.3984 7.59948L145.384 74.7186L161.185 120.786L174.61 136.443L128.5 174.5L116.5 161.5L70.0039 144.02L9.57444 75.7386Z" fill="url(#paint0_linear_33_56)" stroke="#646368" stroke-miterlimit="10"/>
      <g filter="url(#filter0_ii_33_56)">
      <path d="M76.8921 102.198C91.2356 101.523 102.316 89.3481 101.641 75.0046C100.967 60.6611 88.7919 49.5804 74.4483 50.2552C60.1048 50.93 49.0241 63.1048 49.6989 77.4484C50.3738 91.7919 62.5486 102.873 76.8921 102.198Z" fill="#E6E6E6"/>
      </g>
      <path d="M101.142 75.0281C101.804 89.0958 90.9363 101.036 76.8686 101.698C62.8009 102.36 50.8602 91.4926 50.1984 77.4249C49.5365 63.3572 60.4041 51.4165 74.4718 50.7547C88.5395 50.0928 100.48 60.9604 101.142 75.0281Z" stroke="#B4B4B4"/>
      <path d="M129.624 68.4918L88.4733 21.5191C86.081 18.7884 81.9148 18.5244 79.168 20.9295L20.9767 71.8818C18.2299 74.2869 17.9424 78.4504 20.3348 81.1812L61.4857 128.154C63.878 130.885 68.0441 131.149 70.791 128.743L128.982 77.7912C131.729 75.3861 132.016 71.2226 129.624 68.4918Z" stroke="#6D6E71" stroke-miterlimit="10" stroke-linejoin="round" stroke-dasharray="3 3"/>
      <rect x="12.5221" y="84.4883" width="2.82043" height="78.2609" rx="0.75" transform="rotate(-41.6079 12.5221 84.4883)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
      <rect x="81.2438" y="9.30633" width="2.82043" height="92.652" rx="0.75" transform="rotate(48.8984 81.2438 9.30633)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
      <rect x="68.3502" y="16.1951" width="2.82043" height="78.3848" rx="0.75" transform="rotate(48.8984 68.3502 16.1951)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
      <g filter="url(#filter1_d_33_56)">
      <rect x="139.286" y="86" width="3.32043" height="78.8848" rx="1" transform="rotate(48.7247 139.286 86)" fill="#D9D9D9"/>
      <rect x="139.263" y="86.3528" width="2.82043" height="78.3848" rx="0.75" transform="rotate(48.7247 139.263 86.3528)" stroke="#646368" stroke-width="0.5"/>
      </g>
      <g filter="url(#filter2_i_33_56)">
      <path d="M158.199 114.057L160.184 120.661L120.505 158.108L113.724 156.334L158.199 114.057Z" fill="#D9D9D9"/>
      </g>
      <rect x="78.8998" y="7.06942" width="2.82043" height="14.1727" rx="0.75" transform="rotate(48.8984 78.8998 7.06942)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
      <rect x="85.7645" y="9.58631" width="4.11222" height="16.2628" rx="0.75" transform="rotate(-40.9737 85.7645 9.58631)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
      <rect x="78.7277" y="7.20163" width="4.25493" height="6.52926" rx="0.75" transform="rotate(-41.0114 78.7277 7.20163)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
      <g filter="url(#filter3_d_33_56)">
      <rect x="92.4558" y="17.9719" width="8.8399" height="8.26994" rx="1" transform="rotate(-40.9737 92.4558 17.9719)" fill="#D9D9D9"/>
      <rect x="92.8085" y="17.9967" width="8.3399" height="7.76994" rx="0.75" transform="rotate(-40.9737 92.8085 17.9967)" stroke="#646368" stroke-width="0.5"/>
      </g>
      <g filter="url(#filter4_d_33_56)">
      <rect x="3.02832" y="72.282" width="8.8399" height="8.26994" rx="1" transform="rotate(-39.2173 3.02832 72.282)" fill="#D9D9D9"/>
      <rect x="3.38007" y="72.3176" width="8.3399" height="7.76994" rx="0.75" transform="rotate(-39.2173 3.38007 72.3176)" stroke="#646368" stroke-width="0.5"/>
      </g>
      <rect x="127.274" y="58.16" width="4.11222" height="16.2628" rx="0.75" transform="rotate(-40.9737 127.274 58.16)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
      <g filter="url(#filter5_d_33_56)">
      <rect x="133.966" y="66.5456" width="8.8399" height="8.26994" rx="1" transform="rotate(-40.9737 133.966 66.5456)" fill="#D9D9D9"/>
      <rect x="134.318" y="66.5704" width="8.3399" height="7.76994" rx="0.75" transform="rotate(-40.9737 134.318 66.5704)" stroke="#646368" stroke-width="0.5"/>
      </g>
      <rect x="22.0275" y="99.2355" width="2.82043" height="60.8718" rx="0.75" transform="rotate(-41.6079 22.0275 99.2355)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
      <rect x="145.607" y="80.1503" width="3.01386" height="27.8646" rx="0.75" transform="rotate(-18.7836 145.607 80.1503)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
      <rect x="69.3198" y="146.829" width="4.79133" height="32.2394" rx="0.75" transform="rotate(-70.2286 69.3198 146.829)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
      <g filter="url(#filter6_i_33_56)">
      <rect x="92.5576" y="73.4298" width="5" height="3" rx="1" transform="rotate(-2.69366 92.5576 73.4298)" fill="#D9D9D9"/>
      </g>
      <g filter="url(#filter7_i_33_56)">
      <rect x="73.7786" y="57.2945" width="4" height="3" rx="1" transform="rotate(-2.69366 73.7786 57.2945)" fill="#D9D9D9"/>
      </g>
      <g filter="url(#filter8_i_33_56)">
      <rect x="73.4846" y="72.3249" width="6" height="6" rx="3" transform="rotate(-2.69366 73.4846 72.3249)" fill="#767070"/>
      </g>
      <g filter="url(#filter9_i_33_56)">
      <rect x="55.6924" y="77.1664" width="5" height="2" rx="1" transform="rotate(-2.69366 55.6924 77.1664)" fill="#D9D9D9"/>
      </g>
      <g filter="url(#filter10_i_33_56)">
      <rect x="75.4702" y="93.2547" width="4" height="2" rx="1" transform="rotate(-2.69366 75.4702 93.2547)" fill="#D9D9D9"/>
      </g>
      <path d="M81.8427 30.0751C81.8676 30.1115 81.9099 30.1346 81.9562 30.1324L86.1401 29.9356C86.1864 29.9334 86.2263 29.9065 86.2477 29.8679L88.1739 26.246C88.1953 26.2074 88.1929 26.1574 88.168 26.121L85.9103 22.696C85.8854 22.6596 85.8431 22.6365 85.7968 22.6387L81.6129 22.8355C81.5666 22.8377 81.5267 22.8646 81.5053 22.9032L79.5791 26.5251C79.5578 26.5637 79.5601 26.6137 79.585 26.6501L81.8427 30.0751ZM81.6992 23.0819L85.7341 22.8921L87.9114 26.1957L86.0538 29.6892L82.019 29.879L79.8416 26.5754L81.6992 23.0819Z" fill="#646368"/>
      <path d="M83.9543 28.0442C84.8677 28.0013 85.5777 27.2212 85.5347 26.3078C85.4917 25.3944 84.7117 24.6845 83.7983 24.7275C82.8849 24.7704 82.1749 25.5505 82.2179 26.4639C82.2609 27.3773 83.0409 28.0872 83.9543 28.0442ZM83.8103 24.9826C84.5833 24.9462 85.2432 25.5468 85.2795 26.3198C85.3159 27.0929 84.7154 27.7527 83.9423 27.7891C83.1693 27.8255 82.5094 27.2249 82.4731 26.4519C82.4367 25.6788 83.0372 25.019 83.8103 24.9826Z" fill="#646368"/>
      <path d="M122.564 76.0264C122.589 76.0628 122.632 76.0859 122.678 76.0837L126.862 75.8869C126.908 75.8847 126.948 75.8578 126.969 75.8192L128.896 72.1973C128.917 72.1587 128.915 72.1087 128.89 72.0723L126.632 68.6473C126.607 68.6109 126.565 68.5878 126.519 68.59L122.335 68.7868C122.288 68.789 122.248 68.8159 122.227 68.8545L120.301 72.4764C120.279 72.515 120.282 72.565 120.307 72.6014L122.564 76.0264ZM122.421 69.0332L126.456 68.8434L128.633 72.147L126.776 75.6405L122.741 75.8303L120.563 72.5267L122.421 69.0332Z" fill="#646368"/>
      <path d="M124.676 73.9955C125.589 73.9526 126.299 73.1725 126.256 72.2591C126.213 71.3457 125.433 70.6358 124.52 70.6788C123.607 70.7217 122.897 71.5018 122.94 72.4152C122.983 73.3286 123.763 74.0385 124.676 73.9955ZM124.532 70.9339C125.305 70.8975 125.965 71.4981 126.001 72.2711C126.038 73.0442 125.437 73.704 124.664 73.7404C123.891 73.7768 123.231 73.1762 123.195 72.4032C123.158 71.6301 123.759 70.9703 124.532 70.9339Z" fill="#646368"/>
      <path d="M58.3961 123.73C58.4224 123.769 58.4672 123.793 58.5161 123.791L62.9438 123.583C62.9928 123.58 63.035 123.552 63.0576 123.511L65.096 119.678C65.1187 119.637 65.1162 119.584 65.0898 119.546L62.7006 115.921C62.6742 115.883 62.6295 115.858 62.5805 115.861L58.1528 116.069C58.1039 116.071 58.0616 116.1 58.039 116.141L56.0006 119.973C55.978 120.014 55.9805 120.067 56.0068 120.106L58.3961 123.73ZM58.2442 116.33L62.5141 116.129L64.8182 119.625L62.8525 123.322L58.5826 123.523L56.2784 120.027L58.2442 116.33Z" fill="#646368"/>
      <path d="M60.6308 121.581C61.5974 121.535 62.3487 120.71 62.3032 119.743C62.2578 118.777 61.4323 118.025 60.4657 118.071C59.4991 118.116 58.7478 118.942 58.7933 119.908C58.8387 120.875 59.6642 121.626 60.6308 121.581ZM60.4784 118.341C61.2965 118.302 61.9947 118.938 62.0332 119.756C62.0717 120.574 61.4362 121.272 60.6181 121.311C59.8 121.349 59.1017 120.714 59.0633 119.896C59.0248 119.078 59.6603 118.379 60.4784 118.341Z" fill="#646368"/>
      <path d="M23.7243 80.6766C23.7492 80.713 23.7915 80.7361 23.8378 80.7339L28.0217 80.5371C28.068 80.5349 28.1079 80.508 28.1293 80.4694L30.0555 76.8475C30.0769 76.8089 30.0745 76.7589 30.0496 76.7225L27.7919 73.2975C27.767 73.2611 27.7247 73.238 27.6784 73.2402L23.4945 73.437C23.4482 73.4392 23.4083 73.4661 23.3869 73.5047L21.4607 77.1266C21.4394 77.1652 21.4417 77.2152 21.4666 77.2516L23.7243 80.6766ZM23.5808 73.6834L27.6156 73.4936L29.793 76.7972L27.9354 80.2907L23.9006 80.4805L21.7232 77.1769L23.5808 73.6834Z" fill="#646368"/>
      <path d="M25.8362 78.6457C26.7495 78.6028 27.4595 77.8227 27.4165 76.9093C27.3735 75.9959 26.5935 75.286 25.6801 75.329C24.7667 75.3719 24.0568 76.152 24.0998 77.0654C24.1427 77.9788 24.9228 78.6887 25.8362 78.6457ZM25.6921 75.5841C26.4652 75.5477 27.125 76.1483 27.1614 76.9213C27.1978 77.6944 26.5972 78.3542 25.8242 78.3906C25.0511 78.427 24.3913 77.8264 24.3549 77.0534C24.3185 76.2803 24.9191 75.6205 25.6921 75.5841Z" fill="#646368"/>
       {props.status==='filter' && 
        <svg width="97" height="96" viewBox="-10 -30 87 106" x={30} y={22} >
          <defs>
            <filter id="drop-shadow2" x="-20%" y="-20%" width="140%" height="140%" >
              <feGaussianBlur in="SourceAlpha" stdDeviation="10"/> 
              <feOffset dx="0" dy="0" result="offsetblur"/> 
              <feFlood flood-color="#cf00f7" flood-opacity="1"/> 
              <feComposite in2="offsetblur" operator="in"/>
              <feMerge>
                  <feMergeNode/>
                  <feMergeNode in="SourceGraphic"/>
              </feMerge>
            </filter>
          </defs>
            <g filter="url(#drop-shadow2)" >
            <g filter="url(#filter0_ii_0_1)">
              <path d="M31.3857 1.13056C14.5861 1.18694 1.01365 15.033 1.07078 32.0566C1.12792 49.0802 14.793 62.8348 31.5926 62.7785C48.3922 62.7221 61.9647 48.876 61.9075 31.8524C61.8504 14.8288 48.1853 1.07417 31.3857 1.13056Z" 
              fill="#cf00f7"/>
              </g>
              <path d="M31.3841 0.63056C14.3021 0.687891 0.512745 14.7648 0.570785 32.0583C0.628826 49.3518 14.5123 63.3358 31.5943 63.2785C48.6763 63.2211 62.4656 49.1442 62.4075 31.8507C62.3495 14.5573 48.466 0.57323 31.3841 0.63056Z" 
              />
              <g >
              <rect x="14.0518" y="32.8242" width="3.24465" height="2.43348" rx="1" transform="rotate(179.808 14.0518 32.8242)" fill="#D9D9D9"/>
              </g>
              <g >
              <rect x="31.9543" y="49.7985" width="2.43348" height="2.43348" rx="1" transform="rotate(179.808 31.9543 49.7985)" fill="#D9D9D9"/>
              </g>
              <g >
              <rect x="33.5249" y="34.3816" width="5.67813" height="5.67813" rx="2.83906" transform="rotate(179.808 33.5249 34.3816)" fill="#767070"/>
              </g>
              <g >
              <rect x="50.5537" y="32.7017" width="2.43348" height="2.43348" rx="1" transform="rotate(179.808 50.5537 32.7017)" fill="#D9D9D9"/>
              </g>
              <g >
              <rect x="31.8318" y="13.2966" width="2.43348" height="2.43348" rx="1" transform="rotate(179.808 31.8318 13.2966)" fill="#D9D9D9"/>
              </g>
              
            </g>
        </svg> 
        }
      
      
      <defs>
      <filter id="filter0_ii_33_56" x="45.6697" y="46.2259" width="59.001" height="61.0011" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="3" dy="5"/>
      <feGaussianBlur stdDeviation="2.5"/>
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
      <feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_56"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="-4" dy="-4"/>
      <feGaussianBlur stdDeviation="2.5"/>
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"/>
      <feBlend mode="normal" in2="effect1_innerShadow_33_56" result="effect2_innerShadow_33_56"/>
      </filter>
      <filter id="filter1_d_33_56" x="77.4111" y="84.4112" width="68.6538" height="61.7116" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="1" dy="2"/>
      <feGaussianBlur stdDeviation="2"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_33_56"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_33_56" result="shape"/>
      </filter>
      <filter id="filter2_i_33_56" x="113.724" y="114.057" width="47.4597" height="45.0513" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="1" dy="1"/>
      <feGaussianBlur stdDeviation="1"/>
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_56"/>
      </filter>
      <filter id="filter3_d_33_56" x="91.8665" y="12.5862" width="15.2756" height="15.2189" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="1" dy="2"/>
      <feGaussianBlur stdDeviation="1"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_33_56"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_33_56" result="shape"/>
      </filter>
      <filter id="filter4_d_33_56" x="2.4353" y="67.0998" width="15.2637" height="15.1823" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="1" dy="2"/>
      <feGaussianBlur stdDeviation="1"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_33_56"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_33_56" result="shape"/>
      </filter>
      <filter id="filter5_d_33_56" x="133.376" y="61.1599" width="15.2756" height="15.2189" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="1" dy="2"/>
      <feGaussianBlur stdDeviation="1"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_33_56"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_33_56" result="shape"/>
      </filter>
      <filter id="filter6_i_33_56" x="92.6035" y="73.2406" width="5.0437" height="4.13992" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="1"/>
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_56"/>
      </filter>
      <filter id="filter7_i_33_56" x="73.8245" y="57.1524" width="4.04468" height="4.09293" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="1"/>
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_56"/>
      </filter>
      <filter id="filter8_i_33_56" x="73.6223" y="72.1805" width="6" height="7.00012" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="1"/>
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
      <feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_56"/>
      </filter>
      <filter id="filter9_i_33_56" x="55.7383" y="76.9773" width="4.99658" height="3.14103" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="1"/>
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_56"/>
      </filter>
      <filter id="filter10_i_33_56" x="75.5161" y="93.1126" width="3.9978" height="3.09403" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="1"/>
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_56"/>
      </filter>
      <linearGradient id="paint0_linear_33_56" x1="9.41761" y1="72.5905" x2="192.877" y2="154.317" gradientUnits="userSpaceOnUse">
      <stop stop-color="#DFDEDE"/>
      <stop offset="0.155" stop-color="#E5E5E5"/>
      <stop offset="0.3" stop-color="#DADADA"/>
      <stop offset="0.5" stop-color="#CBCBCC"/>
      <stop offset="0.605" stop-color="#E0E0E0"/>
      <stop offset="1" stop-color="#E9E9E9"/>
      </linearGradient>
      </defs>
      </svg>
      
        
    )
  }