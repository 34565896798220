import {  createContext, useContext, useEffect, useState } from "react";
import { GetUserDetail } from "../components/api/user";
import Cookies from "js-cookie";

const AuthContext = createContext(null);

export const AuthProvider = ({children}) => {
    const [user,setUser] = useState(null);

    useEffect(() => {
        const userId = Cookies.get("userId");
        if (userId) {
            setUserDetail(userId) 
        //   setUser(JSON.parse(storedUser));
        }
      }, []);

    const setUserSession = async (userData) => {
        const userId = userData.user?.global_id
        setUserDetail(userId)
    }

    const clearUserSession = () =>{
        setUser(null)
        // Cookies.remove("userId");
    }

    const setUserDetail = async(userId) =>{
        const res = await GetUserDetail(userId);
        const userDetail =  res?.data?.result || null;
        // console.log(userDetail,'userDetail')
        setUser(userDetail);
    }

    return (
        <AuthContext.Provider value={{user,setUserSession,clearUserSession}} >
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext)