import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';

const CookieCheck = () => {
  useEffect(() => {
    const userCookie = Cookies.get('token');
    if (!userCookie) {
      // Redirect to the home page if the 'user' cookie exists
      window.location.href = '/login';
    } 
  }, []);

  // Return null since this component doesn't render anything
  return null;
};

export default CookieCheck;