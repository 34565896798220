// icon:pipe-valve | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from "react";

function IconPipeValve(props) {
  return (
    <svg
      viewBox="0 0 18 14"
      fill="none"
      height="1em"
      width="1em"
      {...props}
    >

<path d="M8.99998 7.37137C7.97327 7.37137 7.13754 8.16224 7.13754 9.13596C7.13754 10.1097 7.97327 10.9006 8.99998 10.9006C10.0249 10.9006 10.8606 10.1097 10.8606 9.13596C10.8606 8.1622 10.0249 7.37137 8.99998 7.37137ZM13.1382 6.26176H14.5084V12.0102H13.1345C12.1739 13.2591 10.6439 13.9995 8.99998 13.9995C7.35794 13.9995 5.82797 13.2591 4.86733 12.0102H3.49161V6.26176H4.86367C5.8298 5.0128 7.36164 4.27246 8.99998 4.27246C10.6384 4.27246 12.172 5.01277 13.1382 6.26176ZM11.5953 9.13596C11.5953 7.77898 10.4308 6.67461 8.99998 6.67461C7.56733 6.67461 6.40286 7.77902 6.40286 9.13596C6.40286 10.4929 7.56733 11.5973 8.99998 11.5973C10.4308 11.5973 11.5953 10.4929 11.5953 9.13596ZM1.65305 5.23748H1.10205C0.494076 5.23748 0 5.70779 0 6.28439V11.9875C0 12.5641 0.494076 13.0344 1.10205 13.0344H1.65305C2.26103 13.0344 2.75693 12.5641 2.75693 11.9875V6.28439C2.75693 5.70783 2.26103 5.23748 1.65305 5.23748ZM16.8961 5.23748H16.3469C15.7371 5.23748 15.2431 5.70779 15.2431 6.28439V11.9875C15.2431 12.5641 15.7371 13.0344 16.3469 13.0344H16.8961C17.5041 13.0344 18 12.5641 18 11.9875V6.28439C18 5.70783 17.5041 5.23748 16.8961 5.23748Z" fill="currentColor"/>
<path d="M4.67786 1.9527C4.45193 1.7402 4.3252 1.45275 4.3252 1.14445C4.3252 0.51386 4.86702 0 5.53192 0H12.4656C13.1323 0 13.6741 0.51386 13.6741 1.14445C13.6741 1.77504 13.1323 2.2889 12.4656 2.2889H10.4801V3.7591C10.0025 3.6389 9.50476 3.5762 8.99964 3.5762C8.49451 3.5762 7.99678 3.6389 7.51741 3.7591V2.2889H5.53188C5.20684 2.28886 4.90375 2.16866 4.67786 1.9527Z" fill="currentColor"/>
</svg>

  );
}

export default IconPipeValve;
