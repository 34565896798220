import * as React from "react";

function IconCloseArrow(props) {
  return (
    <svg
      viewBox="0 0 9 12"
      fill="none"
      height="1em"
      width="1em"
      {...props}
    >

<path d="M7.45924 5.79854C7.59519 5.89844 7.59519 6.10156 7.45924 6.20146L1.89803 10.2879C1.73293 10.4092 1.5 10.2913 1.5 10.0864L1.5 1.91358C1.5 1.70869 1.73293 1.5908 1.89803 1.71212L7.45924 5.79854Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
</svg>
);
}

export default IconCloseArrow;