import React from "react";

export default function AccountIcon() {
  return (
    <svg
      width="81"
      height="100"
      viewBox="0 0 81 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_0_1)">
        <rect x="8" y="8" width="65" height="89" rx="5" fill="#DFDEE7" />
      </g>
      <svg x="18px" y="30px" width="46" height="46" viewBox="0 0 46 46" fill="none">
        <path
          d="M23 44.5625C34.9086 44.5625 44.5625 34.9086 44.5625 23C44.5625 11.0914 34.9086 1.4375 23 1.4375C11.0914 1.4375 1.4375 11.0914 1.4375 23C1.4375 34.9086 11.0914 44.5625 23 44.5625Z"
          fill="#FF2424"
        />
        <path
          d="M23 44.5625C25.9719 44.5574 28.9106 43.938 31.6317 42.7431C34.3528 41.5483 36.7976 39.8039 38.8125 37.6194V37.0588C38.6829 33.0809 37.0581 29.2982 34.2622 26.4657C31.4664 23.6331 27.7052 21.959 23.7294 21.7776C19.7535 21.5961 15.8555 22.9206 12.8132 25.4867C9.771 28.0528 7.80831 31.6718 7.3169 35.6213L7.23065 37.0588V37.6194C9.24064 39.7986 11.6784 41.5399 14.3917 42.7345C17.105 43.9292 20.0354 44.5514 23 44.5625Z"
          fill="#404040"
        />
        <path
          d="M23 27.3125C28.5574 27.3125 33.0625 22.8074 33.0625 17.25C33.0625 11.6926 28.5574 7.1875 23 7.1875C17.4426 7.1875 12.9375 11.6926 12.9375 17.25C12.9375 22.8074 17.4426 27.3125 23 27.3125Z"
          fill="white"
        />
      </svg>
    </svg>
  );
}
