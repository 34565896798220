import React from "react";
import Header from "../../Parts/headerComponents/header";
import Footer from "../../Parts/footerComponents/footer";
import TabNav from "../../Parts/tabNav/tabNav";

const motor_url = process.env.REACT_APP_VIA_MOTOR;
export default function Motor(props) {
  return (
    <div>
      <Header layoutdesign={props.layoutdesign} />
      <div className="p-2 px-20 bg-blue-50 flex gap-4 middle-screen">
        <div className="flex-1">
          <TabNav tabs={props.layoutdesign.tabs} type="screens"></TabNav>
          <div className="bg-blue-100 pt-0 overflow-hidden">
            <iframe
             title="motor"
              src={motor_url}
              className="w-full h-3/4 gb-iframe"
            ></iframe>
          </div>
        </div>
      </div>
      <Footer layoutdesign={props.layoutdesign} />
    </div>
  );
}
