import React, { useState, useEffect, useRef } from "react";



export default function SystemConfigEditor({ selectedObject }) {


  const [objectConfig, setObjectConfig] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);



  const fetchObjectConfiguration = async () => {
    try {
      // Replace 'API_ENDPOINT' with the actual endpoint URL
      const API_URL = `${process.env.REACT_APP_VIA_OBJECT_API}/api/ObjectConfiguration/${selectedObject["$dtId"]}`;
      const response = await fetch(API_URL);
      if (!response.ok) {
        throw new Error("Failed to fetch");
      }
      const data = await response.json();
      // console.log(data,'fetchObjectConfiguration')
      setObjectConfig(data);
    } catch (error) {
      setError(error.message);
    }
  };

  const init = async () => {
    setIsLoading(true);
    setObjectConfig({})
    fetchObjectConfiguration()
  };

  useEffect(() => {
    init();
  }, [selectedObject]);


  return (
    <div className="h-[calc(100vh-320px)] bg-white overflow-y-auto grid w-9/12">
    <div class="relative overflow-x-auto">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 ">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50  ">
            <tr className="divide-x-2 w-full">
              <th scope="col" class="sticky top-0 bg-[#B6D3EA]   px-4 py-2 w-1/2">
                Name
              </th>
              <th scope="col" class="sticky top-0 bg-[#B6D3EA]   px-3 py-2 w-1/2">
                Value
              </th>
              
            </tr>
          </thead>
          <tbody >
            {Object.keys(objectConfig).map((key,index) => (
              <tr key={index} class="bg-white border-b ">
                <th
                  scope="row"
                  class="px-4 py-[2px] font-medium text-gray-900 whitespace-nowrap "
                >
                  {/* <p className="w-20 truncate" title={getPropertyDisplayName(key)}> */}
                    {key}
                    {/* </p> */}
                </th>
                <td class="px-4 py-[2px] text-gray-900">
                  {/* <input
                    className="p-1  rounded border-0 border-[#ccc]  w-[calc(100%-1px)]"
                    type="text"
                    name={key}
                  /> */}
                  {objectConfig[key]}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </div>
  );
}
