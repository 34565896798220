import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown, X } from "heroicons-react";
const useOutsideClick = (callback) => {
  const ref = useRef();

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [callback]);

  return ref;
};

const CustomMultiSelect = ({ options, value, onChange, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useOutsideClick(() => setIsOpen(false));

  const handleToggle = () => setIsOpen(!isOpen);

  const handleSelect = (option) => {
    const newValue = value.includes(option.value)
      ? value.filter((v) => v !== option.value)
      : [...value, option.value];
    onChange(newValue);
  };

  return (
    <div className="relative w-[calc(100%-8px)]" ref={ref}>
      <div
        className="p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white"
        onClick={handleToggle}
      >
        <div className="flex flex-wrap gap-1">
          {value.length > 0 ? (
            value.map((v) => (
              <span key={v} className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">
                {options.find((opt) => opt.value === v)?.label}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelect({ value: v });
                  }}
                  className="ml-1 text-blue-600 hover:text-blue-800"
                >
                  <X size={14} />
                </button>
              </span>
            ))
          ) : (
            <span className="text-gray-400">{placeholder}</span>
          )}
        </div>
        <ChevronDown size={20} className={`transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </div>
      {isOpen && options.length>0 &&  (
        <div className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg max-h-60 overflow-auto">
          {options.map((option) => (
            <div
              key={option.value}
              className={`p-2 m-1 hover:bg-gray-100 cursor-pointer ${
                value.includes(option.value) ? 'bg-blue-50' : ''
              }`}
              onClick={() => handleSelect(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
      {isOpen && options.length == 0 &&  (
        <div className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg max-h-60 overflow-auto">
            <div
              className={`p-2 m-1 hover:bg-gray-100`}
            >
              {placeholder}
            </div>
          
        </div>
      )}
    </div>
  );
};

export default CustomMultiSelect;