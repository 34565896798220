

import * as React from "react";

function IconFile(props) {
  return (
    <svg
      viewBox="0 0 12 14"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
<path d="M8 4V0.3125C8.11458 0.385417 8.20833 0.458333 8.28125 0.53125L11.4688 3.71875C11.5417 3.79167 11.6146 3.88542 11.6875 4H8ZM7 4.25C7 4.45833 7.07292 4.63542 7.21875 4.78125C7.36458 4.92708 7.54167 5 7.75 5H12V13.25C12 13.4583 11.9271 13.6354 11.7812 13.7812C11.6354 13.9271 11.4583 14 11.25 14H0.75C0.541667 14 0.364583 13.9271 0.21875 13.7812C0.0729167 13.6354 0 13.4583 0 13.25V0.75C0 0.541667 0.0729167 0.364583 0.21875 0.21875C0.364583 0.0729167 0.541667 0 0.75 0H7V4.25Z" fill="currentColor"/>
      
    </svg>
  );
}

export default IconFile;

