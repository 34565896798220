import React, { useState, useEffect, useRef } from "react";

import IconConfig from "../../../icons/Config";
export default function ConfigEditorTab(props) {

  return (
    <button
      onClick={() => {
        props.setCurrentTab("configEditor");
        props.setHead("Tool Configuration");
        props.setsubhead("");
        props.updatePage("setpageConfigEditor");
      }}
      className="mx-2"
    >
      <div

  className={`flex flex-col justify-center items-center rounded-md space-y-2 w-[60px] h-[60px] px-2 py-2 ${props.currentTab == "configEditor"?'btn_active':'btn_inactive'}`}
>
        <IconConfig className="w-6 h-6" />

        <p className="uppercase font-semibold text-xs">Config</p>
      </div>
    </button>
  );
}
