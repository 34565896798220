


export default function EventsIcon(props) {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" className={props.className}>
            <g opacity="0.6">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0628 12.1875H1.43748C0.989078 12.1875 0.625305 12.5515 0.625305 13C0.625305 13.4485 0.989078 13.8125 1.43748 13.8125H16.0628C16.5112 13.8125 16.8749 13.4485 16.8749 13C16.8749 12.5515 16.5112 12.1875 16.0628 12.1875Z" 
                ></path>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9136 12.9999L11.4255 9.51184C11.1083 9.19496 11.1083 8.67984 11.4255 8.36296C11.7424 8.04568 12.2575 8.04568 12.5744 8.36296L16.6369 12.4255C16.9542 12.7427 16.9542 13.2571 16.6369 13.5743L12.5744 17.6368C12.2575 17.9541 11.7424 17.9541 11.4255 17.6368C11.1083 17.32 11.1083 16.8048 11.4255 16.488L14.9136 12.9999Z" 
                ></path>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 16V16.875C5 20.2556 7.61375 23 10.8333 23H19.1667C22.3862 23 25 20.2556 25 16.875V8.125C25 4.74444 22.3862 2 19.1667 2H10.8333C7.61375 2 5 4.74444 5 8.125V12.5C5 12.983 5.37333 13.375 5.83333 13.375C6.29333 13.375 6.66667 12.983 6.66667 12.5V8.125C6.66667 5.71044 8.53375 3.75 10.8333 3.75H19.1667C21.4662 3.75 23.3333 5.71044 23.3333 8.125V16.875C23.3333 19.2896 21.4662 21.25 19.1667 21.25H10.8333C8.53375 21.25 6.66667 19.2896 6.66667 16.875V16C6.66667 15.517 6.29333 15.125 5.83333 15.125C5.37333 15.125 5 15.517 5 16Z" 
                  stroke-width="1"></path></g></svg>
    )
}