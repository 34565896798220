import React from 'react';
import Images from '../../../Images';
import CookieCheck from '../../Screens/Auth/cookieCheck';

  export default function Header(props) { 
    return (<>      
    <header className="flex  bg-gray-200 px-20">
      {props.layoutdesign.header.map(({ name, module, type ,data}, index) => (
        
             React.cloneElement(module,data)
      ))}
      </header>
      <CookieCheck />
    </>
   

    )
  }