import * as React from "react";

function IconFolder(props) {
  return (
    <svg
      viewBox="0 0 16 14"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >

<path d="M0 12.1333C0 12.6284 0.184374 13.1032 0.512563 13.4533C0.840752 13.8033 1.28587 14 1.75 14H12.25C12.7141 14 13.1592 13.8033 13.4874 13.4533C13.8156 13.1032 14 12.6284 14 12.1333V4.2C14 3.70493 13.8156 3.23014 13.4874 2.88007C13.1592 2.53 12.7141 2.33333 12.25 2.33333H8.3125C6.75413 2.33333 7 0 4.62131 0H1.75C1.28587 0 0.840752 0.196667 0.512563 0.546734C0.184374 0.896802 0 1.3716 0 1.86667V12.1333Z" fill="currentColor" fillOpacity="0.6"/>
<path d="M14.3912 4H11.4708C9.05058 4 9.07506 6.38095 7.48934 6.38095H3.48387C2.50075 6.38095 1.62311 7.2338 1.52297 8.2857L1.304 10.1305L1.0708 12.0952L1.06189 12.0943C1.01071 12.3828 0.762819 12.57 0.483326 12.57C0.197158 12.57 -0.00890229 12.3286 0.00533939 12.0257C0.00444929 12.049 0 12.0714 0 12.0952C0.000668611 12.5499 0.153736 12.9892 0.431398 13.3334C0.70906 13.6776 1.0929 13.9038 1.51318 13.9709C1.60096 13.9908 1.69048 14.0005 1.78021 14H13.3516C14.3347 14 15.2128 13.1471 15.3125 12.0952L15.9907 5.90476C16.0904 4.85285 15.3743 4 14.3912 4Z" fill="currentColor" />
</svg>
  );
}

export default IconFolder;