import React from "react";
import Header from "../../Parts/headerComponents/header";
import Footer from "../../Parts/footerComponents/footer";
import TabNav from "../../Parts/tabNav/tabNav";
import "./copilot.css";
import { useLocation } from "react-router-dom";

// const io_url = process.env.REACT_APP_VIA_IO;
const io_url = "https://copilot.getvia.ai/chat-page";
export default function Copilot(props) {
  const location = useLocation();
  return (
    <div>
       {location.pathname!="/" &&<Header layoutdesign={props.layoutdesign} />}
      <div className="p-2 px-20 bg-blue-50 flex gap-4">
        <div className="flex-1">
          {/* <TabNav tabs={props.layoutdesign.tabs} type="events"></TabNav> */}
          <div className="bg-blue-100 pt-0 overflow-hidden">
            <iframe
              title="Events & Alarms"
              src={io_url}
              className="w-auto m-2 io-iframe"
              style={{width: "99%"}}
            ></iframe>
          </div>
        </div>
      </div>
       {location.pathname!="/" &&<Footer layoutdesign={props.layoutdesign} />}
    </div>
  );
}