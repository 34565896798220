
import React from 'react';
import Images from '../../../Images';
import { useLocation,Link } from 'react-router-dom';
  export default function TabNav(props) { 
    const location = useLocation();
    return (
        <>
      <div className='flex border-b border-gray-300 relative z-20'>
        {props.tabs.map(({ name, tabs}) => (
          props?.type==name&&
          tabs.map((i,j)=>(
            <Link to={i.link} className={`p-1 px-6 font-semibold rounded-t-lg  ${i.link===location.pathname&& 'bg-blue-100 ' }  ${i.subtabs?.map((x,y)=>(location.pathname==x.link ? ' bg-blue-100 ':' '))}`}>{i.name}</Link>
          ))
          
        ))}

    </div>
   
          {(location.pathname=='/iec'||location.pathname=='/gasbox'||location.pathname=='/motor'||location.pathname=='/motor2')&& 
            <div className='flex gap-1 mt-2 border-b border-red-400 pl-4'>
              <>
                <Link to={'/iec'} className={`p-1 px-10 rounded-t ${'/iec'===location.pathname? 'bg-red-200': 'bg-gray-300'}`}>Main</Link>
                <Link to={'/gasbox'} className={`p-1 px-10  rounded-t  ${'/gasbox'===location.pathname? 'bg-red-200': 'bg-gray-300'}`}>Gasbox</Link>
                {/* <Link to={'/motor'} className={`p-1 px-4 font-bold rounded-t-lg ${'/motor'===location.pathname&& 'bg-blue-100'}`}>Motor1</Link> */}
                <Link to={'/motor2'} className={`p-1 px-10  rounded-t  ${'/motor2'===location.pathname? 'bg-red-200': 'bg-gray-300'}`}>Motor</Link>
              </>
            </div>
          }
          {(location.pathname=='/dicingRIEChamber'||location.pathname=='/gasBoxdicingrie'||location.pathname=='/dicingmotor')&& 
           <div className='flex gap-1 mt-2 border-b border-red-400 pl-4'>
           <>
             <Link to={'/dicingRIEChamber'} className={`p-1 px-10 rounded-t ${'/dicingRIEChamber'===location.pathname? 'bg-red-200': 'bg-gray-300'}`}>Main</Link>
             <Link to={'/gasBoxdicingrie'} className={`p-1 px-10  rounded-t  ${'/gasBoxdicingrie'===location.pathname? 'bg-red-200': 'bg-gray-300'}`}>Gasbox</Link>
                <Link to={'/dicingmotor'} className={`p-1 px-10  rounded-t  ${'/dicingmotor'===location.pathname? 'bg-red-200': 'bg-gray-300'}`}>Motor</Link>
           </>
           </div>

          }    
              
      
    </>
    )
  }

