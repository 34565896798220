
import React from 'react';
import Images from '../../../Images';
  export default function Footer(props) { 
    return (
        <footer className="flex  gap-2 items-stretch bg-gray-200 px-20 fixed bottom-0 w-full py-1">
        {props.layoutdesign.footer.map(({ name, module, type ,data}, index) => (
          <div key={index} className='flex-1 '>
              {React.cloneElement(module,data)}
              </div>
            ))}
      </footer>
    )
  }

