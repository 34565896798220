import axios from 'axios'
import Cookies from 'js-cookie'

const token = Cookies.get('token')

const config = {
  headers: { Authorization: `Bearer ${token}` },
}

const ioBaseURl = process.env.REACT_APP_VIA_CORE_API

export const ioApi = axios.create({
  baseURL: ioBaseURl,
})


export const ReadAllChannelEnums = async () => {
  try {
    const res = await ioApi.get('/api/IOChannel/enums', config)
    return {
      status: 200,
      data: res || null,
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return { error: error.response.data.message }
    }
    return { status: 400, error }
  }
}

export const ReadAllChannels = async () => {
  try {
    const res = await ioApi.get('/api/IOChannel', config)
    return {
      status: 200,
      data: res || null,
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return { error: error.response.data.message }
    }
    return { status: 400, error }
  }
}


export const ReadAllChannelStates = async () => {
  try {
    const res = await ioApi.get(`api/IO/state/get/all`, config)
    return {
      status: 200,
      data: res || null,
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return { error: error.response.data.message }
    }
    return { status: 400, error }
  }
}
export const getAllChambers = async () => {
  const url =
  process.env.REACT_APP_VIA_RULE_ENGINE_API +"/api/Chamber";
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await res.json();
    if (json.errors) {
      console.error(json.errors);
      throw new Error("Failed to fetch API");
    }
    return json;
  } catch (error) {
    // throw new Error("Failed to fetch API");
    return error;
  }
}
export const getAllRecipesByChamber = async (chamberId) => {
  const url =
  process.env.REACT_APP_VIA_RULE_ENGINE_API +"/api/Recipes/ByChamber/"+chamberId;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await res.json();
    if (json.errors) {
      console.error(json.errors);
      throw new Error("Failed to fetch API");
    }
    return json;
  } catch (error) {
    // throw new Error("Failed to fetch API");
    return error;
  }
}