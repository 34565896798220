import React, {useEffect, useState, useRef} from 'react';
import { useLocation,Link } from 'react-router-dom';

export default function NavButton(props) {
    const location = useLocation();
    const [path, setPath] = useState(location.pathname);
    
    const children = props.data?.children;

    return (
        <Link to={props.link} className={`flex m-2 bg-gray-300 ${props.className}  ${path === props.link || (children && children.indexOf(path) !== -1) ? 'navactive' : ''}`}>{React.cloneElement(props.icon,props.data)}{props.title}</Link>
    )
}