import React, { useEffect } from 'react';
import './App.css';
import Images from './Images';
import NavButton from './components/Parts/footerComponents/navButton';
import TraficIcon from './Images/svg/icons/traficIcon';
import E95 from './components/Screens/e95/e95';
import { BrowserRouter, Routes, Route, useHistory } from "react-router-dom";
import Cookies from 'js-cookie';
import Iec from './components/Screens/Iec/iec';
import Gasbox from './components/Screens/Iec/gasbox';
import IoeditorIcon from './Images/svg/icons/ioeditorIcon';
import EventsIcon from './Images/svg/icons/eventsIcon';
import RecepiIcon from './Images/svg/icons/recepiIcon';
import GasboxIcon from './Images/svg/icons/gasboxIcon';
import Analyticsicon from './Images/svg/icons/analyticsicon';
import Analytics from './components/Screens/analytics/analytics';
import Login from './components/Screens/Auth/login';
import CookieCheck from './components/Screens/Auth/cookieCheck';
import ScreensIcon from './Images/svg/icons/screensIcon';
import Recipes from './components/Screens/recipes/recipes';
import Events from './components/Screens/events/events';
import IO from './components/Screens/io/io';
import Alarms from './components/Screens/alarms/alarms';
import Interlocks from './components/Screens/interlocks/interlocks';
import Sqns from './components/Screens/sqns/sqns';
import E30 from './components/Screens/e30/e30';
import Motor2 from './components/Screens/Iec/motor2';
import Motor from './components/Screens/Iec/motor';
import E95Maintenance from './components/Screens/e95/e95Maintenance';
import DicingRIE from './components/Screens/dicingRIE/dicingRIE';
import Dicingmotor from './components/Screens/dicingRIE/dicingmotor';
import GasBoxdicingrie from './components/Screens/dicingRIE/gasBoxdicingrie';
import TabScreen from './components/Screens/tabScreen';
import Copilot from './components/Screens/copilot/copilot';
import { AuthProvider } from './context/AuthContext';
import { LoggingProvider } from './context/LoggingContext';

const layoutdesign = {
  header: [
    {
      name: 'Images.svg.logo',
      module: <Images.svg.Logo />,
      type: 'image',
      data: { className: 'flex-1 pt-4', height: 101, width: 100 }
    },
    {
      name: 'Images.svg.EventsDisplay',
      module: <Images.svg.EventsDisplay />,
      type: 'image',
      data: { className: '', height: 101, width: 300 }
    },
    {
      name: 'Images.svg.AlarmNotification',
      module: <Images.svg.AlarmNotification />,
      type: 'image',
      data: { className: '', height: 86, width: 86 }
    },
    {
      name: 'Images.svg.AlarmNotification',
      module: <Images.svg.Copilot />,
      type: 'image',
      data: { className: '', height: 86, width: 86 }
    },
    // {
    //   name:'Images.svg.Alarm',
    //   module:<Images.svg.Alarm />,
    //   type:'image',
    //   data:{className:'',height:101,width:100}
    // },
    {
      name: 'Images.svg.CommunicationHost',
      module: <Images.svg.CommunicationHost />,
      type: 'image',
      title: 'chost',
      data: { class: 'pb-12' }
    },
    {
      name: 'Images.svg.Alarmpause',
      module: <Images.svg.Alarmpause />,
      type: 'image',
      data: { class: 'pb-12' }
    },
    {
      name: 'Images.svg.Alarmseveritylevel',
      module: <Images.svg.Alarmseveritylevel />,
      type: 'image',
      data: { className: 'pt-2' }
    },
    {
      name: 'Images.svg.Logout',
      module: <Images.svg.Logout />,
      type: 'image',
      title: 'chost',
      data: { class: 'pb-12' }
    },
  ],
  footer: [
    // {
    //   name:'Images.svg.Alarm',module:<NavButton />,type:'link',
    //   data:{className:'navbtn ',height:101,width:100,title:'Back',link:'',icon:<Backbtn />,data:{className:'navicon '}}
    // },
    {
      name: 'Images.svg.Alarm',
      module: <NavButton />,
      type: 'link',
      data: { className: 'navbtn ', height: 101, width: 100, title: 'Home', link: '/', icon: <TraficIcon />, data: { children: ['/e30'], className: 'navicon' } }
    },
    {
      name: 'Images.svg.Alarm',
      module: <NavButton />,
      type: 'link',
      data: { className: 'navbtn', height: 101, width: 100, title: 'Maintenance', link: '/iec', icon: <ScreensIcon />, data: { children: ['/gasbox', '/motor2', '/transferchamber', '/dicingRIEChamber', '/gasBoxdicingrie'], className: 'navicon mr-2' } }
    },
    {
      name: 'Images.svg.Alarm',
      module: <NavButton />,
      type: 'link',
      data: { className: 'navbtn', height: 101, width: 100, title: 'Analytics', link: '/analytics', icon: <Analyticsicon />, data: { className: 'navicon mr-2' } }
    },
    {
      name: 'Images.svg.Alarm',
      module: <NavButton />,
      type: 'link',
      data: { className: 'navbtn', height: 101, width: 100, title: 'I/O Editor', link: '/io', icon: <IoeditorIcon />, data: { className: 'navicon  mr-2' } }
    },
    {
      name: 'Images.svg.Alarm',
      module: <NavButton />,
      type: 'link',
      data: { className: 'navbtn', height: 101, width: 100, title: 'Recipes', link: '/recipes', icon: <RecepiIcon />, data: { children: ['/sqns', '/interlocks'], className: 'navicon  mr-2' } }
    },
    {
      name: 'Images.svg.Alarm',
      module: <NavButton />,
      type: 'link',
      data: { className: 'navbtn', height: 101, width: 100, title: 'Events & Alarms', link: '/events', icon: <EventsIcon />, data: { children: ['/alarms'], className: 'navicon  mr-2' } }
    }
  ],
  tabs: [
    {
      name: 'home',
      tabs: [
        { link: '/', name: 'Main' },
        { link: '/e30', name: 'Secondary' },
        { link: '', name: 'Other' },
      ]

    },
    // {
    //   name:'screens',
    //   tabs:[
    //   {link:'/iec',name:'Isotropic Etch Chamber'},
    //   {link:'/gasbox',name:'Gas Box'},
    //   {link:'/transferchamber',name:'Transfer Chamber'},
    //   {link:'/motor',name:'Motor Option 1'},
    //   {link:'/motor2',name:'Motor Option 2'}
    //   ]
    // },
    {
      name: 'screens',
      tabs: [
        {
          link: '/iec', name: 'Isotropic Etch Chamber',
          subtabs: [
            { link: '/iec', name: 'Main' },
            { link: '/gasbox', name: 'Gas Box' },
            // {link:'/motor',name:'Motor Option 1'},
            { link: '/motor2', name: 'Motor Option 2' }
          ]
        },
        {
          link: '/dicingRIEChamber', name: 'Dicing RIE Chamber',
          subtabs: [
            { link: '/dicingRIEChamber', name: 'Main' },
            { link: '/gasBoxdicingrie', name: 'Gas Box' },
            { link: '/dicingmotor', name: 'Gas Box' },
          ]
        },
        { link: '/transferchamber', name: 'Transfer Chamber' },
      ],

    },
    {
      name: 'events',
      tabs: [
        { link: '/events', name: 'Events' },
        { link: '/alarms', name: 'Alarms' }
      ]

    }, {
      name: 'recipes',
      tabs: [
        { link: '/recipes', name: 'Recipe Editor' },
        { link: '/sqns', name: 'Sequence' },
        { link: '/interlocks', name: 'Interlocks' },
      ]

    }
  ]
}

const App = () => {

  return (
    <AuthProvider>
      <LoggingProvider>
      <BrowserRouter>
        <Routes>
          <Route path="login" element={<Login />} />
          {/* <Route path="/" element={<E95 layoutdesign={layoutdesign}/>} /> */}
          <Route path="/" element={<TabScreen layoutdesign={layoutdesign}/>} />
          <Route path="TabScreen" element={<TabScreen layoutdesign={layoutdesign}  />} />
          <Route path="iec" element={<Iec layoutdesign={layoutdesign} />} />
          <Route path="gasbox" element={<Gasbox layoutdesign={layoutdesign} />} />
          <Route path="transferchamber" element={<E95Maintenance layoutdesign={layoutdesign} />} />
          <Route path="motor" element={<Motor layoutdesign={layoutdesign} />} />
          <Route path="motor2" element={<Motor2 layoutdesign={layoutdesign} />} />
          <Route path="io" element={<IO layoutdesign={layoutdesign} />} />
          <Route path="copilot" element={<Copilot layoutdesign={layoutdesign} />} />
          <Route path="recipes" element={<Recipes layoutdesign={layoutdesign} />} />
          <Route path="events" element={<Events layoutdesign={layoutdesign} />} />
          <Route path="alarms" element={<Alarms layoutdesign={layoutdesign} />} />
          <Route path="analytics" element={<Analytics layoutdesign={layoutdesign} />} />
          <Route path="interlocks" element={<Interlocks layoutdesign={layoutdesign} />} />
          <Route path="sqns" element={<Sqns layoutdesign={layoutdesign} />} />
          <Route path="e30" element={<E30 layoutdesign={layoutdesign} />} />
          <Route path="dicingRIEChamber" element={<DicingRIE layoutdesign={layoutdesign} />} />
          <Route path="gasBoxdicingrie" element={<GasBoxdicingrie layoutdesign={layoutdesign} />} />
          <Route path="dicingmotor" element={<Dicingmotor layoutdesign={layoutdesign} />} />
        </Routes>
      </BrowserRouter>
      </LoggingProvider>
    </AuthProvider>
  );
}

export default App;
