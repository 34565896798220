import { useEffect, useRef, useState } from "react";
import "./E94.css";
import { ReadAllChannelStates, ReadAllChannels } from "../../api/api";

export default function E94(props) {


  return (
      <div className="flex-1 flex p-4">
        <div className="flex-1">
          <div className="flex flex-col p-0 no-scroll">
                      <div className="w-full">
                        <table className="slots-table table-auto w-full bg-white rounded-t-xl overflow-hidden shadow-lg text-left border-collapse  border-gray-400 bordeer-table">
                          <thead className="bg-gradient-to-r from-black to-gray-700 text-white ">
                            <tr>		
                              <th className="py-2 px-2 border-l-5 border-black">  CJ ID  </th>
                              <th className="py-2 border-l-5 border-black">  Source Carrier ID </th>
                              <th className="py-2  border-l-5 border-black">  Destn Carrier ID </th>
                              <th className="py-2 border-l-5 border-black">  CJ Template </th>
                              <th className="py-2  border-l-5 border-black">  Start Method </th>
                              <th className="py-2  border-l-5 border-black">  CJ Status </th>
                            </tr>
                          </thead>
                          <tr><th colSpan={6} className="px-4 py-2">Active</th></tr>					
                          {/* <tr><td  className="px-4 py-1"></td><td>A123</td><td> B123 </td><td> Template1 </td><td> Auto </td><td> Executing </td></tr>   */}
                            <tr><td className="p-4">   </td><td>   </td><td>   </td><td>   </td><td>   </td><td>   </td></tr>   
                            <tr><td className="p-4">   </td><td>   </td><td>   </td><td>   </td><td>   </td><td>   </td></tr>   
                            <tr><td className="p-4">   </td><td>   </td><td>   </td><td>   </td><td>   </td><td>   </td></tr>   
                          {/* <tr><td  className="px-4">CJIDA2</td><td>A123</td><td>B123</td><td> Template2 </td><td> Auto </td><td> Selected </td></tr>  					
                          <tr><td  className="px-4"> CJIDA3	</td><td> A123	 </td><td> B123	  </td><td> Template3	 </td><td> AUAutoTO	 </td><td> WaitingForStart </td></tr>   */}
                            <tr><th colSpan={6} className="px-4 py-2">Queued</th></tr>					
                            {/* <tr><td className="px-4 py-1"> CJ540 </td><td> A123 </td><td> B123 </td><td>  Template1</td><td> Auto </td><td> Queued </td></tr>   */}
                            {/* <tr><td className="px-4"> CJIDQ3 </td><td> A123 </td><td> B123 </td><td> Template3 </td><td>Auto  </td><td>Queued  </td></tr>  					
                            <tr><td className="px-4"> CJIDQ2 </td><td> A123 </td><td>B123  </td><td> Template2 </td><td>  Auto</td><td> Queued </td></tr>   */}
                            <tr><td className="p-4">   </td><td>   </td><td>   </td><td>   </td><td>   </td><td>   </td></tr>   
                            <tr><td className="p-4">   </td><td>   </td><td>   </td><td>   </td><td>   </td><td>   </td></tr>   
                            <tr><td className="p-4">   </td><td>   </td><td>   </td><td>   </td><td>   </td><td>   </td></tr>   
                            <tr><th colSpan={6}  className="px-4 py-2">Completed</th> </tr>
                            {/* <tr><td className="px-4 py-1"> CJ894 </td><td> A123 </td><td>B123  </td><td>Template1  </td><td> Auto </td><td> Completed </td></tr>  
                            <tr><td className="px-4 py-1"> CJ579 </td><td> A123 </td><td>  B123</td><td> Template2 </td><td>  Auto</td><td> Completed </td></tr>  
                            <tr className=""><td className="px-4 py-1"> CJ465 </td><td> A123 </td><td> B123 </td><td> Template3 </td><td> Auto </td><td> Completed </td></tr>   */}
                            <tr><td className="p-4">   </td><td>   </td><td>   </td><td>   </td><td>   </td><td>   </td></tr>   
                            <tr><td className="p-4">   </td><td>   </td><td>   </td><td>   </td><td>   </td><td>   </td></tr>   
                            <tr><td className="p-4 mb-2">   </td><td>   </td><td>   </td><td>   </td><td>   </td><td>   </td></tr> 
                        </table>
                      </div>
          </div>
        </div>
        <div className="w-40 grid  grid-flow-row">
          <div className="flex-1 grid grid-flow-row gap-3 pl-4">
            <button class="e-87 md:text-xs lg:text-xs ">Create</button>
            <button class="e-87 opacity-65  md:text-xs lg:text-xs  mt-4">Start</button>
            <button class="e-87  opacity-65   md:text-xs lg:text-xs ">Pause</button>
            <button class="e-87  opacity-65   md:text-xs lg:text-xs ">Stop</button>
            <button class="e-87  opacity-65   md:text-xs lg:text-xs ">Abort All</button>
            <button class="e-87  opacity-65   md:text-xs lg:text-xs ">Deselect</button>
            <button class="e-87  opacity-65   md:text-xs lg:text-xs mt-4">HOQ</button>
            <button class="e-87  opacity-65   md:text-xs lg:text-xs ">Cancel</button>
          </div>
          <div className="mt-9 pl-4">
            <button class="e-87  opacity-65   md:text-xs lg:text-xs w-full">Delete</button>
          </div>
        </div>
    </div>
  );
}
