


export default function Alarmseveritylevel(props) {
    return (
        <svg width="51" height="94" viewBox="0 0 51 94" fill="none" className={props?.className}>
        <g filter="url(#filter0_ii_0_1)">
        <rect width="51" height="94" rx="5" fill="#CFE5F2"/>
        </g>
        <g filter="url(#filter1_d_0_1)">
        <rect x="13" y="65" width="24" height="14" rx="3" fill="#16F702"/>
        <rect x="13.25" y="65.25" width="23.5" height="13.5" rx="2.75" stroke="#2ADB1A" stroke-width="0.5"/>
        </g>
        <g filter="url(#filter2_d_0_1)">
        <rect x="13" y="48" width="24" height="13" rx="3" fill="#EBFF00"/>
        <rect x="13.25" y="48.25" width="23.5" height="12.5" rx="2.75" stroke="#D4E230" stroke-width="0.5"/>
        </g>
        <g opacity="0.75">
        <g filter="url(#filter3_i_0_1)">
        <rect x="13" y="30" width="24" height="15" rx="3" fill="#FF8A00"/>
        </g>
        <rect x="13.25" y="30.25" width="23.5" height="14.5" rx="2.75" stroke="#D37B13" stroke-width="0.5"/>
        </g>
        <g opacity="0.75">
        <g filter="url(#filter4_i_0_1)">
        <rect x="13" y="12" width="24" height="15" rx="3" fill="#FF0000"/>
        </g>
        <rect x="13.25" y="12.25" width="23.5" height="14.5" rx="2.75" stroke="#D21010" stroke-width="0.5"/>
        </g>
        <defs>
        <filter id="filter0_ii_0_1" x="-4" y="-4" width="59" height="102" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="4" dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_0_1"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="-4" dy="-4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.604167 0 0 0 0 0.604167 0 0 0 0 0.604167 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="effect1_innerShadow_0_1" result="effect2_innerShadow_0_1"/>
        </filter>
        <filter id="filter1_d_0_1" x="3" y="55" width="44" height="34" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.08 0 0 0 0 1 0 0 0 0 0 0 0 0 1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
        </filter>
        <filter id="filter2_d_0_1" x="3" y="38" width="44" height="33" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.98 0 0 0 0 1 0 0 0 0 0 0 0 0 1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
        </filter>
        <filter id="filter3_i_0_1" x="13" y="30" width="27" height="19" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="3" dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_0_1"/>
        </filter>
        <filter id="filter4_i_0_1" x="13" y="12" width="27" height="19" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="3" dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_0_1"/>
        </filter>
        </defs>
        </svg>
        
    )
}