import React from 'react';

import { useNavigate } from 'react-router-dom';

  export default function IsotropicH(props) { 
    const navigate = useNavigate();
    const handleClick = () => {
      navigate('/iec');
    };
    return (
        <svg width="135" height="121" viewBox="0 0 135 121" fill="none" x={props.x} y={props.y} 
        onClick={handleClick}>

        <rect x="78.1393" y="116.252" width="3.55581" height="62.8886" rx="0.75" transform="rotate(89.6445 78.1393 116.252)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
        <rect x="0.276738" y="21.6503" width="3.55581" height="91.0104" rx="0.75" transform="rotate(-0.365837 0.276738 21.6503)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
        <path d="M131.359 92.5983L110.244 92.5982L110.322 115.808L4.25447 116.164L3.88996 7.55597L109.957 7.19999L110.038 31.3558L134.286 31.356C134.469 58.2492 131.177 65.7051 131.359 92.5983Z" fill="url(#paint0_linear_33_55)" stroke="#646368" stroke-miterlimit="10"/>
        <g filter="url(#filter0_i_33_55)">
        <path d="M96.8013 16.9952L55.1594 17.1347C44.5512 18.1533 34.6239 22.8153 27.0668 30.3274C19.5097 37.8396 14.7899 47.7376 13.7106 58.3371C11.8567 81.8625 30.1124 104.111 55.4599 106.825L97.1018 106.685L96.8013 16.9952Z" fill="#76777A"/>
        </g>
        <path d="M27.4193 30.682C34.8889 23.2568 44.6998 18.6466 55.1843 17.6347L96.303 17.4968L96.6001 106.187L55.4859 106.325C30.4137 103.626 12.3808 81.6171 14.2086 58.3819C15.2773 47.8997 19.9456 38.1113 27.4193 30.682Z" stroke="#A2A2A2"/>
        <g filter="url(#filter1_ii_33_55)">
        <path d="M56.3857 31.1306C39.5861 31.1869 26.0136 45.033 26.0708 62.0566C26.1279 79.0802 39.793 92.8348 56.5926 92.7785C73.3922 92.7221 86.9647 78.876 86.9075 61.8524C86.8504 44.8288 73.1853 31.0742 56.3857 31.1306Z" fill="#E6E6E6"/>
        </g>
        <path d="M56.3841 30.6306C39.3021 30.6879 25.5127 44.7648 25.5708 62.0583C25.6288 79.3518 39.5123 93.3358 56.5943 93.2785C73.6763 93.2211 87.4656 79.1442 87.4075 61.8507C87.3495 44.5573 73.466 30.5732 56.3841 30.6306Z" stroke="#B1B1B1"/>
        <rect x="42.4612" y="7.15077" width="3.55581" height="62.8886" rx="0.75" transform="rotate(-90.5478 42.4612 7.15077)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
        <rect x="95.8983" y="7.72418" width="14.1009" height="108.196" rx="0.75" transform="rotate(-0.192296 95.8983 7.72418)" fill="url(#paint1_linear_33_55)" stroke="#646368" stroke-width="0.5"/>
        <g filter="url(#filter2_i_33_55)">
        <rect x="39.0518" y="62.8242" width="3.24465" height="2.43348" rx="1" transform="rotate(179.808 39.0518 62.8242)" fill="#D9D9D9"/>
        </g>
        <g filter="url(#filter3_i_33_55)">
        <rect x="56.9543" y="79.7985" width="2.43348" height="2.43348" rx="1" transform="rotate(179.808 56.9543 79.7985)" fill="#D9D9D9"/>
        </g>
        <g filter="url(#filter4_i_33_55)">
        <rect x="58.5249" y="64.3816" width="5.67813" height="5.67813" rx="2.83906" transform="rotate(179.808 58.5249 64.3816)" fill="#767070"/>
        </g>
        <g filter="url(#filter5_i_33_55)">
        <rect x="75.5537" y="62.7017" width="2.43348" height="2.43348" rx="1" transform="rotate(179.808 75.5537 62.7017)" fill="#D9D9D9"/>
        </g>
        <g filter="url(#filter6_i_33_55)">
        <rect x="56.8318" y="43.2966" width="2.43348" height="2.43348" rx="1" transform="rotate(179.808 56.8318 43.2966)" fill="#D9D9D9"/>
        </g>
        <g filter="url(#filter7_d_33_55)">
        <rect x="110.33" y="31.7605" width="4.86697" height="60.5" transform="rotate(-0.192296 110.33 31.7605)" fill="url(#paint2_linear_33_55)" shape-rendering="crispEdges"/>
        </g>
        <g filter="url(#filter8_d_33_55)">
        <rect x="10.5396" y="0.314529" width="10.7977" height="10.1015" rx="1" transform="rotate(-0.0344908 10.5396 0.314529)" fill="#D9D9D9"/>
        <rect x="10.7897" y="0.564379" width="10.2977" height="9.60147" rx="0.75" transform="rotate(-0.0344908 10.7897 0.564379)" stroke="#646368" stroke-width="0.5"/>
        </g>
        <rect x="0.407793" y="7.25257" width="3.47207" height="14.9469" rx="0.75" transform="rotate(0.594551 0.407793 7.25257)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
        <rect x="0.29023" y="7.69367" width="5.4396" height="10.2353" rx="0.75" transform="rotate(-90.4024 0.29023 7.69367)" fill="#D9D9D9" stroke="#646368" stroke-width="0.5"/>
        <path d="M12.3076 26.1887C12.3359 26.2346 12.3862 26.2651 12.4427 26.2651H17.5589C17.6155 26.2651 17.6658 26.2346 17.6941 26.1887L20.2522 21.8801C20.2804 21.8343 20.2804 21.7732 20.2522 21.7274L17.6941 17.4188C17.6658 17.3729 17.6155 17.3424 17.5589 17.3424H12.4427C12.3862 17.3424 12.3359 17.3729 12.3076 17.4188L9.74948 21.7274C9.7212 21.7732 9.7212 21.8343 9.74948 21.8801L12.3076 26.1887ZM12.5339 17.6479H17.4678L19.9347 21.8037L17.4678 25.9596H12.5339L10.0669 21.8037L12.5339 17.6479Z" fill="#646368"/>
        <path d="M15.0008 23.8316C16.1177 23.8316 17.0287 22.9206 17.0287 21.8037C17.0287 20.6868 16.1177 19.7758 15.0008 19.7758C13.8839 19.7758 12.9729 20.6868 12.9729 21.8037C12.9729 22.9206 13.8839 23.8316 15.0008 23.8316ZM15.0008 20.0878C15.9461 20.0878 16.7167 20.8584 16.7167 21.8037C16.7167 22.7491 15.9461 23.5197 15.0008 23.5197C14.0555 23.5197 13.2849 22.7491 13.2849 21.8037C13.2849 20.8584 14.0555 20.0878 15.0008 20.0878Z" fill="#646368"/>
        <path d="M13.1189 107.305C13.1471 107.351 13.1974 107.381 13.254 107.381H18.3702C18.4268 107.381 18.477 107.351 18.5053 107.305L21.0634 102.996C21.0917 102.95 21.0917 102.889 21.0634 102.843L18.5053 98.5348C18.477 98.489 18.4268 98.4584 18.3702 98.4584H13.254C13.1974 98.4584 13.1471 98.489 13.1189 98.5348L10.5608 102.843C10.5325 102.889 10.5325 102.95 10.5608 102.996L13.1189 107.305ZM13.3451 98.764H18.2791L20.746 102.92L18.2791 107.076H13.3451L10.8782 102.92L13.3451 98.764Z" fill="#646368"/>
        <path d="M15.8121 104.948C16.929 104.948 17.84 104.037 17.84 102.92C17.84 101.803 16.929 100.892 15.8121 100.892C14.6952 100.892 13.7842 101.803 13.7842 102.92C13.7842 104.037 14.6952 104.948 15.8121 104.948ZM15.8121 101.204C16.7574 101.204 17.528 101.974 17.528 102.92C17.528 103.865 16.7574 104.636 15.8121 104.636C14.8668 104.636 14.0962 103.865 14.0962 102.92C14.0962 101.974 14.8668 101.204 15.8121 101.204Z" fill="#646368"/>
        <g filter="url(#filter9_i_33_55)">
        <rect x="126.457" y="92.1423" width="9.16928" height="60.4805" transform="rotate(-179.944 126.457 92.1423)" fill="#EBE7E7"/>
        </g>  
            {props.status==='filter' && 
        <svg width="97" height="96" viewBox="10 -10 67 96" x={20} y={19} >
          <defs>
            <filter id="drop-shadow" x="-20%" y="-20%" width="140%" height="140%">
              <feGaussianBlur in="SourceAlpha" stdDeviation="16"/> 
              <feOffset dx="0" dy="0" result="offsetblur"/> 
              <feFlood flood-color="#cf00f7" flood-opacity="1"/> 
              <feComposite in2="offsetblur" operator="in"/>
              <feMerge>
                  <feMergeNode/>
                  <feMergeNode in="SourceGraphic"/>
              </feMerge>
            </filter>
          </defs>
            <g filter="url(#drop-shadow)" >
            <g filter="url(#filter0_ii_0_1)">
              <path d="M31.3857 1.13056C14.5861 1.18694 1.01365 15.033 1.07078 32.0566C1.12792 49.0802 14.793 62.8348 31.5926 62.7785C48.3922 62.7221 61.9647 48.876 61.9075 31.8524C61.8504 14.8288 48.1853 1.07417 31.3857 1.13056Z" 
              fill="#cf00f7"/>
              </g>
              <path d="M31.3841 0.63056C14.3021 0.687891 0.512745 14.7648 0.570785 32.0583C0.628826 49.3518 14.5123 63.3358 31.5943 63.2785C48.6763 63.2211 62.4656 49.1442 62.4075 31.8507C62.3495 14.5573 48.466 0.57323 31.3841 0.63056Z" 
              />
              <g >
              <rect x="14.0518" y="32.8242" width="3.24465" height="2.43348" rx="1" transform="rotate(179.808 14.0518 32.8242)" fill="#D9D9D9"/>
              </g>
              <g >
              <rect x="31.9543" y="49.7985" width="2.43348" height="2.43348" rx="1" transform="rotate(179.808 31.9543 49.7985)" fill="#D9D9D9"/>
              </g>
              <g >
              <rect x="33.5249" y="34.3816" width="5.67813" height="5.67813" rx="2.83906" transform="rotate(179.808 33.5249 34.3816)" fill="#767070"/>
              </g>
              <g >
              <rect x="50.5537" y="32.7017" width="2.43348" height="2.43348" rx="1" transform="rotate(179.808 50.5537 32.7017)" fill="#D9D9D9"/>
              </g>
              <g >
              <rect x="31.8318" y="13.2966" width="2.43348" height="2.43348" rx="1" transform="rotate(179.808 31.8318 13.2966)" fill="#D9D9D9"/>
              </g>
              
            </g>
        </svg> 
        }
        <defs>




        <filter id="filter0_i_33_55" x="13.5811" y="16.9952" width="83.5208" height="93.8298" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="5"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.7 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_55"/>
        </filter>
        <filter id="filter1_ii_33_55" x="21.0706" y="26.1304" width="69.8372" height="72.6483" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="3" dy="5"/>
        <feGaussianBlur stdDeviation="2.5"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_55"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="-4" dy="-4"/>
        <feGaussianBlur stdDeviation="2.5"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"/>
        <feBlend mode="normal" in2="effect1_innerShadow_33_55" result="effect2_innerShadow_33_55"/>
        </filter>
        <filter id="filter2_i_33_55" x="35.8022" y="60.3941" width="3.24609" height="3.43766" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_55"/>
        </filter>
        <filter id="filter3_i_33_55" x="54.5161" y="77.3684" width="2.43481" height="3.43494" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_55"/>
        </filter>
        <filter id="filter4_i_33_55" x="52.8372" y="58.713" width="5.67822" height="6.67813" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_55"/>
        </filter>
        <filter id="filter5_i_33_55" x="73.1155" y="60.2716" width="2.43481" height="3.43494" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_55"/>
        </filter>
        <filter id="filter6_i_33_55" x="54.3936" y="40.8665" width="2.43481" height="3.43494" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_55"/>
        </filter>
        <filter id="filter7_d_33_55" x="109.33" y="28.7441" width="11.0701" height="66.516" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="2"/>
        <feGaussianBlur stdDeviation="1.5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_33_55"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_33_55" result="shape"/>
        </filter>
        <filter id="filter8_d_33_55" x="9.54004" y="0.308632" width="14.8027" height="14.1068" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="1" dy="2"/>
        <feGaussianBlur stdDeviation="1"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_33_55"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_33_55" result="shape"/>
        </filter>
        <filter id="filter9_i_33_55" x="117.287" y="31.653" width="10.2278" height="61.4893" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="1" dy="1"/>
        <feGaussianBlur stdDeviation="2.5"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_33_55"/>
        </filter>
        <linearGradient id="paint0_linear_33_55" x1="-10.2926" y1="103.548" x2="164.648" y2="22.6555" gradientUnits="userSpaceOnUse">
        <stop stop-color="#9F9F9F"/>
        <stop offset="0.371933" stop-color="#EAEAEA"/>
        <stop offset="0.668989" stop-color="#B9BABD"/>
        <stop offset="1" stop-color="#BFBFBF"/>
        </linearGradient>
        <linearGradient id="paint1_linear_33_55" x1="117.59" y1="131.996" x2="95.7198" y2="1.73017" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FBF6F6"/>
        <stop offset="0.48" stop-color="#AEAEAE"/>
        <stop offset="0.94" stop-color="#FCF7F7" stop-opacity="0"/>
        </linearGradient>
        <linearGradient id="paint2_linear_33_55" x1="102.951" y1="46.7245" x2="127.555" y2="87.3653" gradientUnits="userSpaceOnUse">
        <stop stop-color="#B8B8B8"/>
        <stop offset="1" stop-color="#EFEFEF" stop-opacity="0.42"/>
        </linearGradient>
        </defs>
        </svg>
        
    )
  }