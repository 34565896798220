import React, { useState, useEffect, useRef } from "react";

import IconCopilot from "../../../icons/copilot";
export default function CopilotTab(props) {
  return (
    <button
      onClick={() => {
        props.setCurrentTab("copilot");
        props.setHead("");
        props.setsubhead("");
        props.updatePage("setpageCopilot");
      }}
      className="mx-2"
    >
      <div
        className={`flex flex-col justify-center items-center rounded-md space-y-2 w-[60px] h-[60px] px-2 py-2 ${
          props.currentTab == "copilot" ? "btn_active" : "btn_inactive"
        }`}
      >
        <IconCopilot className="w-6 h-6 text-[#FF2424]" />
        <p className="uppercase font-semibold text-xs">COPILOT</p>
      </div>
    </button>
  );
}
