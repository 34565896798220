


export default function TraficIcon(props) {
    return (
        <svg width="29" height="37" viewBox="0 0 29 37" fill="none" className={props.className}>
        <g opacity="0.8" filter="url(#filter0_d_270_42)">
        <path d="M24 13.6818H19.6875V8.04545L24 3.81818H19.6875V1H5.3125V3.81818H1L5.3125 8.04545V13.6818H1L5.3125 17.9091V23.5455H1L5.3125 27.7727V32H19.6875V27.7727L24 23.5455H19.6875V17.9091L24 13.6818ZM12.5 29.1818C12.1171 29.1903 11.7364 29.1237 11.3802 28.986C11.0239 28.8483 10.6993 28.6421 10.4254 28.3798C10.1516 28.1174 9.93394 27.804 9.78532 27.458C9.6367 27.112 9.5601 26.7405 9.56 26.3651C9.55991 25.9897 9.63632 25.618 9.78476 25.272C9.9332 24.926 10.1507 24.6125 10.4244 24.3499C10.6981 24.0874 11.0226 23.8811 11.3788 23.7432C11.735 23.6053 12.1157 23.5386 12.4986 23.5469C13.2497 23.5631 13.9646 23.867 14.49 24.3935C15.0155 24.92 15.3098 25.6271 15.31 26.3637C15.3102 27.1002 15.0162 27.8075 14.491 28.3342C13.9658 28.8609 13.2512 29.1652 12.5 29.1818ZM12.5 19.3182C12.1171 19.3267 11.7364 19.2601 11.3802 19.1224C11.0239 18.9846 10.6993 18.7785 10.4254 18.5161C10.1516 18.2537 9.93394 17.9404 9.78532 17.5944C9.6367 17.2484 9.5601 16.8768 9.56 16.5014C9.55991 16.126 9.63632 15.7544 9.78476 15.4084C9.9332 15.0623 10.1507 14.7488 10.4244 14.4863C10.6981 14.2238 11.0226 14.0175 11.3788 13.8796C11.735 13.7417 12.1157 13.6749 12.4986 13.6832C13.2497 13.6995 13.9646 14.0034 14.49 14.5299C15.0155 15.0563 15.3098 15.7635 15.31 16.5C15.3102 17.2365 15.0162 17.9439 14.491 18.4706C13.9658 18.9973 13.2512 19.3015 12.5 19.3182ZM12.5 9.45455C12.1171 9.46303 11.7364 9.39645 11.3802 9.25872C11.0239 9.12099 10.6993 8.91488 10.4254 8.65249C10.1516 8.3901 9.93394 8.07671 9.78532 7.73074C9.6367 7.38477 9.5601 7.01318 9.56 6.63779C9.55991 6.2624 9.63632 5.89077 9.78476 5.54472C9.9332 5.19868 10.1507 4.88519 10.4244 4.62267C10.6981 4.36014 11.0226 4.15387 11.3788 4.01596C11.735 3.87806 12.1157 3.81129 12.4986 3.81959C13.2497 3.83587 13.9646 4.13977 14.49 4.66623C15.0155 5.19268 15.3098 5.89986 15.31 6.63638C15.3102 7.3729 15.0162 8.08022 14.491 8.60693C13.9658 9.13365 13.2512 9.43789 12.5 9.45455Z" 
       />
        </g>
        <defs>
        <filter id="filter0_d_270_42" x="0" y="0" width="29" height="37" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="2" dy="2"/>
        <feGaussianBlur stdDeviation="1.5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_270_42"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_270_42" result="shape"/>
        </filter>
        </defs>
        </svg>

        
    )
}