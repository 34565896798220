import React from "react";
import Header from "../../Parts/headerComponents/header";
import Footer from "../../Parts/footerComponents/footer";
import TabNav from "../../Parts/tabNav/tabNav";
import { useLocation } from "react-router-dom";

const gas_box_url = process.env.REACT_APP_VIA_GASBOX;
// const gas_box_url = "http://localhost:3001/";
export default function GasBoxdicingrie(props) {
  
  const location = useLocation();
  return (
    <div>
      {location.pathname!="/" &&<Header layoutdesign={props.layoutdesign} />}
      <div className="px-20 bg-blue-50 flex gap-4 middle-screen">
        <div className="flex-1">
        {location.pathname!="/" && <TabNav tabs={props.layoutdesign.tabs} type="screens"></TabNav>}
          <div className="bg-blue-100 pt-0 overflow-hidden">
            <iframe
             title="GasBox"
              src={gas_box_url+'/gasBoxdicingrie'}
              className="w-full h-3/4 gb-iframe"
            ></iframe>
          </div>
        </div>
      </div>
      {location.pathname!="/" &&<Footer layoutdesign={props.layoutdesign} />}
    </div>
  );
}
