


import React from 'react';
export default function E95Wafer(props) {
    return (
        
        <svg width="97" height="96" viewBox="10 -10 67 96" x={props.x} y={props.y} onClick={()=>{props.wafermodel&& props.wafermodel(props.positionname,props.channelname)}}>
            <g  >
            <path d="M57.4594 45.3409C48.3211 58.11 30.2246 61.2549 17.1187 52.3513C4.01287 43.4478 0.785098 25.8162 9.92339 13.047C19.0617 0.277878 37.1582 -2.86697 50.2641 6.03656C63.3699 14.9401 66.5977 32.5717 57.4594 45.3409ZM26.1049 24.04C23.6938 33.0185 24.8055 29.8624 28.5792 36.3374C35.8262 39.305 32.6046 37.3958 41.3473 34.395C42.2555 25.5131 43.6697 29.547 38.5666 21.0514C31.5091 18.4919 31.7658 21.7394 26.1049 24.04Z" 
            fill={props.fill} stroke='gray'/>
            <path d="M33.6424 51.0345C33.5996 50.439 34.0277 49.9124 34.598 49.8589C45.4223 48.8435 53.5663 38.8237 52.753 27.5211C52.7102 26.9256 53.1372 26.399 53.7085 26.3455C54.2799 26.2919 54.7765 26.732 54.8193 27.3275C55.7182 39.8189 46.7161 50.8944 34.7531 52.0154C34.1829 52.07 33.6854 51.6311 33.6424 51.0345Z" 
            fill="gray"/>
            <path d="M39.0701 21.3759C43.4381 24.3434 44.5149 30.2207 41.4686 34.4774C38.4229 38.7332 32.3897 39.7816 28.0217 36.8142C23.6528 33.8462 22.5775 27.9686 25.6232 23.7128C28.6695 19.4561 34.7012 18.4079 39.0701 21.3759ZM29.2493 35.0988C32.6472 37.4072 37.3387 36.592 39.708 33.2813C42.0766 29.9715 41.2405 25.3997 37.8425 23.0913C34.4454 20.7835 29.7525 21.599 27.3838 24.9088C25.0145 28.2195 25.8522 32.791 29.2493 35.0988Z" 
            fill="#2F3034"/>
            <path d="M31.8684 27.1779C30.4649 28.167 30.1192 30.1264 31.0978 31.545C32.0764 32.9636 34.015 33.3131 35.4185 32.324C36.822 31.3348 37.1678 29.3754 36.1891 27.9568C35.2105 26.5382 33.272 26.1887 31.8684 27.1779ZM34.2351 30.6086C33.7676 30.9381 33.1209 30.8215 32.7949 30.349C32.4689 29.8764 32.5842 29.2228 33.0518 28.8932C33.5193 28.5637 34.166 28.6803 34.492 29.1529C34.818 29.6255 34.7027 30.2791 34.2351 30.6086Z" 
            fill="#2F3034"/>
            </g>
            <text x="24" y="17" font-family="Arial" font-size="10" fontWeight="500" fill="#000">{props.waferID}</text>
        </svg>

    )
}

