import { useEffect, useState } from "react";
import {
  AssignRole,
  CreateUser,
  GetPassword,
  GetRoles,
  GetUsers,
  ResetPassword,
} from "../../api/user";
import { combineNames } from "../../../utils/utils";
import {
  EyeIcon,
  EyeSlashIcon,
  PencilSquareIcon,
} from "@heroicons/react/16/solid";
import { useAuth } from "../../../context/AuthContext";
import { EVENT_TYPES, useLogging } from "../../../context/LoggingContext";

export default function UserList() {
  const { user: userDetail } = useAuth();
  const log = useLogging();

  const [searchTerm, setSearchTerm] = useState("");
  const [userList, setUserList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [searchUsers, setSearchUsers] = useState([]);
  const [passwordVisibility, setPasswordVisibility] = useState({});
  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    const filteredUsers = userList?.filter(
      (user) =>
        user?.username?.toLowerCase()?.includes(term) ||
        combineNames(
          user?.profile?.first_name,
          user?.profile?.middle_name,
          user?.profile?.last_name
        )
          ?.toLowerCase()
          ?.includes(term) ||
        user?.phone?.includes(term) ||
        user?.role?.toLowerCase()?.includes(term)
    );

    setSearchUsers(filteredUsers);
  };
  const getUsers = async () => {
    const res = await GetUsers();
    if (res?.data?.result) {
      setUserList(res?.data?.result);
      const filteredUsers = res?.data?.result?.filter(
        (user) =>
          user?.username?.toLowerCase()?.includes(searchTerm) ||
          combineNames(
            user?.profile?.first_name,
            user?.profile?.middle_name,
            user?.profile?.last_name
          )
            .toLowerCase()
            .includes(searchTerm) ||
          user?.phone?.includes(searchTerm) ||
          user?.role?.toLowerCase()?.includes(searchTerm)
      );
      setSearchUsers(filteredUsers);
    }
  };
  const getRoles = async () => {
    const res = await GetRoles();
    if (res?.data?.result) {
      setRoleList(res?.data?.result);
    }
  };
  const init = async () => {
    // setIsLoading(true);
    try {
      const res = await Promise.all([getUsers(), getRoles()]);
      // setIsLoading(false);
    } catch (error) {
      console.log(error, "error");
      // setIsLoading(false);
    }
  };
  useEffect(() => {
    init();
  }, []);

  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] =
    useState(false);
  const [resetPasswordUserId, setResetPasswordUserId] = useState(null);

  const handleAddUser = async (newUser) => {
    const res = await CreateUser(newUser);
    if (res?.data?.result) {
      const payload = {
        userId: res?.data?.result?.identifier,
        roles: [newUser.role],
      };
      const res2 = await AssignRole(payload);
      if (res2?.data?.result) {
        getUsers();
      }
    }
    setIsAddUserModalOpen(false);
  };
  const handleResetPassword = async (data) => {
    setIsResetPasswordModalOpen(false);
    setResetPasswordUserId(null);
    setPasswordVisibility({});
  };

  const handleChangePassword = async (id) => {
    setIsResetPasswordModalOpen(true);
    setResetPasswordUserId(id);
  };

  const displayRole = (role_permissions, user_type) => {
    if (user_type == "super_admin") {
      return "super_admin";
    } else {
      return role_permissions?.[0]?.name;
    }
  };
  const getPassword = async (id) => {
    const res = await GetPassword(id);
    if (res?.data?.result) {
      return res?.data?.result;
    }
  };
  const togglePasswordVisibility = async (userName, userId) => {
    if (passwordVisibility?.[userName]?.password) {
      setPasswordVisibility((prevVisibility) => ({
        ...prevVisibility,
        [userName]: {
          ...prevVisibility?.[userName],
          visibility: !prevVisibility?.[userName]?.visibility,
        },
      }));
    } else {
      const passwordRes = await getPassword(userId);
      if (passwordRes?.password) {
        setPasswordVisibility((prevVisibility) => ({
          ...prevVisibility,
          [userName]: {
            visibility: !prevVisibility?.[userName]?.visibility,
            password: passwordRes?.password,
          },
        }));
      }
    }
  };
  const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed z-50 inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-6 pt-2 rounded-lg shadow-lg w-96">
          <div className="flex justify-end">
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
          </div>
          {children}
        </div>
      </div>
    );
  };
  const UserForm = ({ onSubmit }) => {
    const [formData, setFormData] = useState({
      username: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      // phone: '',
      role: "",
    });

    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      onSubmit(formData);
    };

    return (
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label
            htmlFor="username"
            className="block text-sm font-medium text-gray-700"
          >
            Username
          </label>
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border h-10 focus:outline-none px-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            required
          />
        </div>
        <div className="flex flex-col gap-2">
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              First Name
            </label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border h-10 focus:outline-none px-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            />
          </div>
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Middle Name
            </label>
            <input
              type="text"
              id="middle_name"
              name="middle_name"
              value={formData.middle_name}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border h-10 focus:outline-none px-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Last Name
            </label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border h-10 focus:outline-none px-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            />
          </div>
        </div>

        {/* <div>
          <label
            htmlFor="phone"
            className="block text-sm font-medium text-gray-700"
          >
            Phone
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border h-10 focus:outline-none px-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            required
          />
        </div> */}
        <div>
          <label
            htmlFor="role"
            className="block text-sm font-medium text-gray-700"
          >
            Role
          </label>
          <select
            id="role"
            name="role"
            value={formData.role}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 border h-10 focus:outline-none shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            required
          >
            <option value="">Select a role</option>
            {roleList.map((role, index) => (
              <option
                key={index}
                value={role.id}
                disabled={
                  role.name == "Admin" &&
                  userDetail?.user_type !== "super_admin"
                }
              >
                {role.name}
              </option>
            ))}
          </select>
        </div>
        <button
          type="submit"
          className="w-full h-10  py-2 px-4  bg-[#BBD6ED] rounded shadow hover:shadow-inner text-xs uppercase font-bold"
        >
          Add User
        </button>
      </form>
    );
  };

  const ResetPasswordForm = ({ onSubmitSuccess, userId }) => {
    const [warning, setWarning] = useState("");

    const [formData, setFormData] = useState({
      old_password: "",
      new_password: "",
      confirm_password: "",
    });

    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (formData.new_password === formData.confirm_password) {
        setWarning("");
        const res = await ResetPassword({ ...formData, userId });
        if (res?.data?.result) {
          setWarning('');
        onSubmitSuccess(true);

        } else {
          setWarning(res?.error);
        }
      } else {
        setWarning("Passwords do not match");
      }
    };

    return (
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label
            htmlFor="old_password"
            className="block text-sm font-medium text-gray-700"
          >
            Old Password
          </label>
          <input
            type="text"
            id="old_password"
            name="old_password"
            value={formData.old_password}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border h-10 focus:outline-none px-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            required
          />
        </div>
        <div className="flex flex-col gap-2">
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              New Password
            </label>
            <input
              type="text"
              id="new_password"
              name="new_password"
              value={formData.new_password}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border h-10 focus:outline-none px-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
              minLength={8}
            />
          </div>
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Confirm Password
            </label>
            <input
              type="text"
              id="confirm_password"
              name="confirm_password"
              value={formData.confirm_password}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border h-10 focus:outline-none px-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
              minLength={8}
            />
          </div>
        </div>
        <button
          type="submit"
          className="w-full h-10  py-2 px-4  bg-[#BBD6ED] rounded shadow hover:shadow-inner text-xs uppercase font-bold"
        >
          Reset Password
        </button>
        {warning && <div className=" mt-2  text-red-500">{warning}</div>}
      </form>
    );
  };
  return (
    <div className="p-2 h-[calc(100vh-186px)] px-20 bg-blue-50 flex gap-4">
      <div className="flex flex-col w-full h-full bg-blue-100 p-2 space-y-2">
        <div className="flex justify-between ">
          <div>
            <form className="max-w-md mx-auto bg-[#DBE9F6]">
              <label
                for="default-search"
                className="mb-2 text-sm font-medium text-gray-900 sr-only "
              >
                Search
              </label>
              <div className="relative bg-[#DBE9F6] w-72">
                <input
                  type="search"
                  id="default-search"
                  className="block w-full px-4 py-2 pe-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:ring-blue-500 focus:border-blue-500 "
                  placeholder="Search for users"
                  value={searchTerm}
                  onChange={handleSearch}
                  required
                />
                <div className="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 "
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
              </div>
            </form>
          </div>
          <button
            className=" h-10 px-4 py-2 bg-[#BBD6ED] rounded shadow hover:shadow-inner text-xs uppercase font-semibold"
            onClick={() => setIsAddUserModalOpen(true)}
          >
            Add users
          </button>
        </div>
        <div className="relative  h-[calc(100vh-340px)] bg-[#E9F2FA] overflow-auto rounded-t-lg border border-gray-200">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase bg-[#B6D3EA]  ">
              <tr className="">
                <th
                  scope="col"
                  className="sticky -top-[1px] bg-[#B6D3EA] border border-[#ccc] px-4 py-2 w-4/12"
                >
                  User Name
                </th>
                <th
                  scope="col"
                  className="sticky -top-[1px] bg-[#B6D3EA] border border-[#ccc] px-3 py-2 w-4/12"
                >
                  Name
                </th>

                <th
                  scope="col"
                  className="sticky -top-[1px] bg-[#B6D3EA] border border-[#ccc]  px-3 py-2 w-2/12"
                >
                  Role
                </th>
                <th
                  scope="col"
                  className="sticky -top-[1px] bg-[#B6D3EA]  border border-[#ccc] px-3 py-2 w-2/12 text-center"
                >
                  Password
                </th>
              </tr>
            </thead>
            <tbody>
              {searchUsers.map(
                ({
                  id,
                  username,
                  profile,
                  phone,
                  role_permissions,
                  user_type,
                }) => (
                  <tr
                    key={username}
                    className="bg-[#E9F2FA] border border-[#ccc] "
                  >
                    <th
                      scope="row"
                      className="px-4 py-2 font-normal border border-[#ccc]  whitespace-nowrap "
                    >
                      {/* <p className="w-20 truncate" title={name}> */}
                      {username}
                      {/* </p> */}
                    </th>
                    <td className="px-2 py-2 border border-[#ccc]">
                      {combineNames(
                        profile?.first_name,
                        profile?.middle_name,
                        profile?.last_name
                      )}
                    </td>
                    <td className="px-2 py-2 border border-[#ccc]">
                      {displayRole(role_permissions, user_type)}
                    </td>
                    <td className="flex items-center justify-center gap-1 px-2 py-2  border-[#ccc]">
                      <p className="flex items-center justify-center">
                        {passwordVisibility?.[username]?.visibility
                          ? passwordVisibility?.[username]?.password
                          : "******"}{" "}
                      </p>
                      <button
                        className="flex items-center justify-center"
                        onClick={() => togglePasswordVisibility(username, id)}
                      >
                        {passwordVisibility?.[username]?.visibility ? (
                          <EyeIcon className="w-4 h-4" />
                        ) : (
                          <EyeSlashIcon className="w-4 h-4" />
                        )}{" "}
                      </button>
                      {userDetail?.user_type == "super_admin" && (
                        <button
                          className="flex items-center justify-center"
                          onClick={() => handleChangePassword(id)}
                        >
                          {<PencilSquareIcon className="w-4 h-4" />}
                        </button>
                      )}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
        <Modal
          isOpen={isAddUserModalOpen}
          onClose={() => {setIsAddUserModalOpen(false);log(EVENT_TYPES.CLOSE_USER_CREATION_POPUP);
          }}
        >
          <h2 className="text-xl font-bold mb-4">Add New User</h2>
          <UserForm onSubmit={handleAddUser} />
        </Modal>
        <Modal
          isOpen={isResetPasswordModalOpen}
          onClose={() => {setIsResetPasswordModalOpen(false);log(EVENT_TYPES.CLOSE_PASSWORD_CHANGE_POPUP)}}
        >
          <h2 className="text-xl font-bold mb-4">Reset Password</h2>
          <ResetPasswordForm
            userId={resetPasswordUserId}
            onSubmitSuccess={handleResetPassword}
          />
        </Modal>
      </div>
    </div>
  );
}
