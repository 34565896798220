


export default function IoeditorIcon(props) {
    return (
        <svg width="26" height="27" viewBox="0 0 26 27" fill="none" className={props.className}>
            <path d="M25 14C25.552 14 26 13.552 26 13C26 12.448 25.552 12 25 12H22V10H25C25.552 10 26 9.552 26 9C26 8.448 25.552 8 25 8H22V7.66667C22 5.644 20.356 4 18.3333 4H18V1C18 0.448 17.552 0 17 0C16.448 0 16 0.448 16 1V4H14V1C14 0.448 13.552 0 13 0C12.448 0 12 0.448 12 1V4H10V1C10 0.448 9.552 0 9 0C8.448 0 8 0.448 8 1V4H7.66667C5.644 4 4 5.644 4 7.66667V8H1C0.448 8 0 8.448 0 9C0 9.552 0.448 10 1 10H4V12H1C0.448 12 0 12.448 0 13C0 13.552 0.448 14 1 14H4V16H1C0.448 16 0 16.448 0 17C0 17.552 0.448 18 1 18H4V18.3333C4 20.356 5.644 22 7.66667 22H8V25C8 25.552 8.448 26 9 26C9.552 26 10 25.552 10 25V22H12V25C12 25.552 12.448 26 13 26C13.552 26 14 25.552 14 25V22H16V25C16 25.552 16.448 26 17 26C17.552 26 18 25.552 18 25V22H18.3333C20.356 22 22 20.356 22 18.3333V18H25C25.552 18 26 17.552 26 17C26 16.448 25.552 16 25 16H22V14H25ZM20 18.3333C20 19.252 19.252 20 18.3333 20H7.66667C6.748 20 6 19.252 6 18.3333V7.66667C6 6.748 6.748 6 7.66667 6H18.3333C19.252 6 20 6.748 20 7.66667V18.3333Z" 
             ></path>
            <path d="M9 17.5C8.72386 17.5 8.5 17.2761 8.5 17C8.5 16.7239 8.72386 16.5 9 16.5C9.27614 16.5 9.5 16.7239 9.5 17C9.5 17.2761 9.27614 17.5 9 17.5ZM9 13.5C8.72386 13.5 8.5 13.2761 8.5 13C8.5 12.7239 8.72386 12.5 9 12.5C9.27614 12.5 9.5 12.7239 9.5 13C9.5 13.2761 9.27614 13.5 9 13.5ZM9 9.5C8.72386 9.5 8.5 9.27614 8.5 9C8.5 8.72386 8.72386 8.5 9 8.5C9.27614 8.5 9.5 8.72386 9.5 9C9.5 9.27614 9.27614 9.5 9 9.5Z" 
                 ></path>
            <path d="M13 17.5C12.7239 17.5 12.5 17.2761 12.5 17C12.5 16.7239 12.7239 16.5 13 16.5C13.2761 16.5 13.5 16.7239 13.5 17C13.5 17.2761 13.2761 17.5 13 17.5ZM13 13.5C12.7239 13.5 12.5 13.2761 12.5 13C12.5 12.7239 12.7239 12.5 13 12.5C13.2761 12.5 13.5 12.7239 13.5 13C13.5 13.2761 13.2761 13.5 13 13.5ZM13 9.5C12.7239 9.5 12.5 9.27614 12.5 9C12.5 8.72386 12.7239 8.5 13 8.5C13.2761 8.5 13.5 8.72386 13.5 9C13.5 9.27614 13.2761 9.5 13 9.5Z" 
                 ></path>
            <path d="M17 17.5C16.7239 17.5 16.5 17.2761 16.5 17C16.5 16.7239 16.7239 16.5 17 16.5C17.2761 16.5 17.5 16.7239 17.5 17C17.5 17.2761 17.2761 17.5 17 17.5ZM17 13.5C16.7239 13.5 16.5 13.2761 16.5 13C16.5 12.7239 16.7239 12.5 17 12.5C17.2761 12.5 17.5 12.7239 17.5 13C17.5 13.2761 17.2761 13.5 17 13.5ZM17 9.5C16.7239 9.5 16.5 9.27614 16.5 9C16.5 8.72386 16.7239 8.5 17 8.5C17.2761 8.5 17.5 8.72386 17.5 9C17.5 9.27614 17.2761 9.5 17 9.5Z" 
                 ></path>
            </svg>

        
    )
}