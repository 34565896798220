


export default function Logo(props) {
    return (
        <div  className={props.className}>
        {/* <img src="./samba.jpeg" className="rounded-md shadow h-9 mt-3 p-1 bg-white"/> */}
        
        <svg width="144" height="69" viewBox="0 0 144 69" fill="none">
        <rect width="144" height="69" fill="url(#pattern0)"/>
        <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0_270_509" transform="matrix(0.00147275 0 0 0.00307357 0 -0.0209707)"/>
        </pattern>
        <image id="image0_270_509" width="679" height="339" href="./via.png"/>
        
        </defs>
        </svg>
        </div>
        
    )
}