import React, { useEffect, useRef, useState, useReducer } from "react";
import { ObjectService, ObjectSubscriptionService } from "@getvia/sdk";

const objectId = "dtmi:com:via:Robot:VacuumRobot;1"; //TODO: make it configurable
const viaConfig = {
  objectServiceOptions: {
    apiToken: "test",
    baseUrl: process.env.REACT_APP_VIA_OBJECT_API,
    wsUrl: process.env.REACT_APP_VIA_OBJECT_NOTIFICATION_HUB,
  },
};
const objectService = new ObjectService(viaConfig.objectServiceOptions);
const objectSubscriptionService = new ObjectSubscriptionService(
  viaConfig.objectServiceOptions
);

const HomeCommand = "Home";
const PickCommand = "Pick";
const PlaceCommand = "Place";
const GotoCommand = "Goto";
const ConnectCommand = "ConnectCmd";

const robotDefaultState = {
  VacuumRobot: {
    ConnectionStatus: "Disconnected",
  },
};

export default function RobotControls(props) {
  const { e95State } = props;
  const Configuration = useRef();
  const subscriptionRef = useRef();
  const [robotState, setRobotState] = useState(robotDefaultState);
  const [popUp, setPopUp] = useState("hidden");
  const [arm, setArm] = useState("");
  const [station, setStation] = useState("");
  const [slot, setSlot] = useState("");

  const SlotComponent = () => {
    return (
      <>
        <div className="p-2 border-t">Slot</div>
        <div>
          <input
            className="border p-2"
            name="slot"
            type="text"
            value={slot}
            onChange={(e) => setSlot(() => e.target.value)}
          />
        </div>
      </>
    );
  };

  const StationComponent = () => {
    return (
      <>
        <div className="p-2 border-t">Station</div>
        <div className="border-t">
          <select
            className="w-full p-2 rounded"
            value={station}
            onChange={(e) => setStation(() => e.target.value)}
          >
            {props.vtmStaions.map(station => (
            <option key={station.value+station.name} value={station.value}>{station.name}</option>
          ))}
          </select>
        </div>
      </>
    );
  };

  const ArmComponent = () => {
    return (
      <>
        <div className="p-2">Arm</div>
        <div className="">
          <select
            className="w-full p-2 rounded"
            value={arm}
            onChange={(e) => setArm(() => e.target.value)}
          >
            {props.vtmArms.map(arms => (
            <option key={arms.value+arms.name} value={arms.value}>{arms.name
              }</option>))}
          </select>
        </div>
      </>
    );
  };

  // const [alarm, dispatch] = useReducer(AlarmReducer, { value: false, AlarmChannels: [] });

  function flattenDigitalTwinsByName(data) {
    const flattened = {};
    data.digitalTwins.forEach((twin) => {
      flattened[twin?.Name] = twin;
    });
    return flattened;
  }

  // const fetchObjectState = async () => {
  //   const response = await objectService.GetObjectGraphById(objectId);

  //   if (response) {
  //     Configuration.current = response;
  //     var flattenObject = flattenDigitalTwinsByName(response);
  //     setRobotState(() => flattenObject);
  //   }
  // };

  // const updateState = (object) => {
  //   debugger;
  //   Configuration.current.digitalTwins = Configuration.current.digitalTwins.map(
  //     (dt) => {
  //       if (dt.$dtId === object.$dtId) {
  //         const updatedObject = { ...dt, ...object };
  //         return updatedObject;
  //       }
  //       return dt;
  //     }
  //   );
  //   var flattenObject = flattenDigitalTwinsByName(Configuration.current);
  //   setRobotState(() => flattenObject);
  // };
  // const subscribeToNotification = () => {
  //   const onNewMessage = (message) => {
  //     const object = JSON.parse(message);
  //     updateState(object);
  //   };
  //   const objectSubscriptions = Configuration.current.digitalTwins.map(
  //     (fdt) => fdt.$dtId
  //   );

  //   const subscription = {
  //     channels: objectSubscriptions,
  //     onNewMessage: onNewMessage,
  //   };
  //   subscriptionRef.current = subscription;
  //   objectSubscriptionService.subscribeObjects(subscription);
  // };
  // const unSubscribe = () => {
  //   if (subscriptionRef.current && subscriptionRef.current.channels) {
  //     objectSubscriptionService?.unSubscribeObjects(subscriptionRef.current);
  //   }
  // };

  useEffect(() => {
    if(props.vtmStaions.length > 0 && props.vtmStaions[0].value) {
      setStation(props.vtmStaions[0].value.toString())
     } 
   if(props.vtmArms.length > 0 && props.vtmArms[0].value) {
    setArm(props.vtmArms[0].value.toString())
   } 
  }, [props])

  useEffect(() => {
    const fetchState = async () => {
      // await fetchObjectState();
      // subscribeToNotification();
    };
    fetchState();
    return () => {
      // unSubscribe();
    };
  }, []);

  const executeCommand = async (objectId, commandName, params) => {
    const request = {
      instanceId: objectId,
      commandName: commandName,
      parameters: params,
    };
    const response = await objectService.ExecuteCommand(request);

    console.log(response);
  };

  const executeHandler = () => {
    if (popUp === PlaceCommand || popUp === PickCommand) {
      executeCommand(objectId, popUp, {
        Arm: arm,
        Slot: slot,
        Station: station,
      });
    }

    if (popUp === GotoCommand) {
      executeCommand(objectId, GotoCommand, { Station: station });
    }

    // setArm(() => null);
    setSlot(() => null);
    // setStation(() => null);
    setPopUp(() => "hidden");
  };

  return (
    <>
      <div className="border border-black p-3 rounded-lg mb-5 mt-10  shadow">
        <span className="-mt-6 block bg-white px-2 border border-black ml-0 rounded-xl mb-2 w-36 text-center">
          Vacuum Robot
        </span>
        <div className="grid grid-cols-3 gap-2 w-60 ">
          <div> </div>
          <div className="col-span-3 grid grid-cols-2 gap-3 pb-2">
            <div className="">
              <button
              disabled={e95State?.find(o => o.name === "VTMStatus").currentValue==2&& true}
                className=" w-full bgPrime2 shadow-shadow100 font-bold text-xl px-2 py-1 rounded-lg transition-all hover:bg-white disabled:opacity-20"
                onClick={() => {
                  executeCommand(objectId, HomeCommand, {});
                }}
              >
                Home
              </button>
            </div>
            <div className="">
              <button disabled={e95State?.find(o => o.name === "VTMStatus").currentValue==2&& true}
                className="w-full bgPrime2 shadow-shadow100 font-bold text-xl px-2 py-1 rounded-lg transition-all hover:bg-white disabled:opacity-20"
                onClick={() => setPopUp(GotoCommand)}
              >
                Goto
              </button>{" "}
            </div>
          </div>
          <div className="col-span-3 grid grid-cols-2 gap-3 pb-2">
            <div className="">
              <button disabled={e95State?.find(o => o.name === "VTMStatus").currentValue==2&& true}
                className="w-full bgPrime2 shadow-shadow100 font-bold text-xl px-2 py-1 rounded-lg transition-all hover:bg-white disabled:opacity-20"
                onClick={() => setPopUp(PickCommand)}
              >
                Pick
              </button>{" "}
            </div>
            <div className="">
              <button disabled={e95State?.find(o => o.name === "VTMStatus").currentValue==2&& true}
                className="w-full bgPrime2 shadow-shadow100 font-bold text-xl px-2 py-1 rounded-lg transition-all hover:bg-white disabled:opacity-20"
                onClick={() => setPopUp(PlaceCommand)}
              >
                Place
              </button>{" "}
            </div>
          </div>
          {/* <div className="col-span-3 grid grid-cols-1 gap-3 pb-2 hidden">
            <div className="">
              <button
                className="w-full bgPrime2 shadow-shadow100 font-bold text-xl px-2 py-1 rounded-lg transition-all hover:bg-white"
                onClick={() =>
                  executeCommand(objectId, ConnectCommand, {
                    value:
                      robotState.VacuumRobot.ConnectionStatus === "Connected"
                        ? "Disconnect"
                        : "Connect",
                  })
                }
              >
                {robotState.VacuumRobot.ConnectionStatus === "Connected"
                  ? "Disconnect"
                  : "Connect"}
              </button>
            </div>
          </div>
          <div className="col-span-3 grid grid-cols-1 gap-3 hidden">
            <div className="bg-white p-2 px-4 rounded shadow">
              {robotState.VacuumRobot.ConnectionStatus}
            </div>
          </div> */}
        </div>
      </div>
      <div
        className={` fixed top-0 left-0 h-full w-full bg-black bg-opacity-15 z-50 justify-center align-middle ${popUp}`}
      >
        <div
          className="absolute w-full h-full "
          onClick={() => setPopUp("hidden")}
        ></div>
        <div className="bg-white rounded-lg overflow-hidden w-2/6 mx-auto relative  top-36">
          <button
            className=" bg-red-500 text-white rounded-full h-6 w-6 text-lg leading-4 hover:bg-opacity-80 absolute z-30 right-2 top-2"
            onClick={() => setPopUp("hidden")}
          >
            x
          </button>
          <div className="text-lg p-3 font-bold bg-gray-300">
            Set Vacuum Robot {popUp}{" "}
          </div>
          <div className="p-3 gap-4 grid grid-cols-2">
            {(popUp === PickCommand || popUp === PlaceCommand) && (
              <ArmComponent></ArmComponent>
            )}
            {(popUp === PickCommand ||
              popUp === GotoCommand ||
              popUp === PlaceCommand) && <StationComponent></StationComponent>}
            {(popUp === PickCommand || popUp === PlaceCommand) && (
              <SlotComponent></SlotComponent>
            )}
            <button
              className="w-28 bgPrime2 shadow-shadow100 font-bold text-xl px-2 py-1 rounded-lg transition-all hover:bg-white"
              onClick={executeHandler}
            >
              Move
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
